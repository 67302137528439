import React, { useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";

//libraries
import { Drawer, Modal } from "antd";
// import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";

//assets
import stop from "assets/svg/stop.svg";
import link from "assets/svg/link.svg";
import globe from "assets/svg/globe.svg";
import poll from "assets/svg/pollType.svg";
import people from "assets/svg/people.svg";
import settings from "assets/svg/settings.svg";
import whitePlay from "assets/svg/whitePlay.svg";
import outlinePlay from "assets/svg/outline-play.svg";
import messagenotif from "assets/svg/messagenotif.svg";

//components
import Timer from "../Timer";
import EndPresentations from "views/Admin/ActivePresentation/Modal";
// import AddGuest from "views/Admin/ActivePresentation/Modal/AddGuest";
import FlexContainer from "components/UI/StyleComponents/FlexContainer";
import AllViewers from "views/Admin/ActivePresentation/Drawers/AllViewers";
import PollDrawer from "views/Admin/ActivePresentation/Drawers/PollDrawer";
import AllQuestions from "views/Admin/ActivePresentation/Drawers/AllQuestions";
import StartPresentation from "views/Admin/ActivePresentation/Modal/StartPresentation";

//hooks

//slices
import {
  getConnectionState,
  getSignalRState,
} from "store/SignalR/signalRSlice";
import { getPresentationState } from "store/Presentations/presentationSlice";
import { useGetViewersList } from "hooks/SignalR/Listeners/Presenter/useGetViewersList";

//SIGNALR
//invokers
import { GetUsersList } from "hooks/SignalR/Invokers/Presenter/GetUsersList";
import { GetQuestions } from "hooks/SignalR/Invokers/Presenter/GetQuestions";

//listeners
import { useGetQuestions } from "hooks/SignalR/Listeners/Presenter/useGetQuestions";
import SchedulePresentation from "views/Admin/Presentation/Modals/SchedulePresentation";
import { EndSignalRConnection } from "hooks/SignalR/Invokers/Presenter/endSignalRConnection";
import SignalRStatusBar from "components/UI/SignalRStatus";
import { useSignalREventListener } from "hooks/SignalR/Listeners/Global/useSignalRListener";

//utils
import { storeViewerCount } from "utils/activePresentationUtils";
import { toast } from "sonner";

export default function Navbar({
  connection,
  presentationId,
  endPresentation,
  beginPresentation,
  isPresentationActive,
  setIsPresentationActive,
}) {
  const connectionState = getConnectionState();
  const navigate = useNavigate();

  //useref

  // const addGuestModalRef = useRef();
  const scheduleModalRef = useRef();
  const isMountedRef = useRef(true);
  const moreDropdownRef = useRef(null);

  //useselector
  const { connectionStatus } = useSelector(getSignalRState);
  const { presentationDetails } = useSelector(getPresentationState);

  //hook

  useGetViewersList(connection, presentationId, connectionState, (users) => {
    storeViewerCount(users);
    let viewCount = users?.filter((user) => {
      return user?.status === 1 || user?.status === 0;
    });
    setViewersCount(viewCount?.length);
  });

  useGetQuestions(connection, presentationId, (questions) => {
    if (!questions) return;
    const questionLength = questions?.length;
    setQuestionCount(questionLength);
    let storedLength;

    if (localStorage.getItem("questionLength", questionLength)) {
      storedLength = JSON.parse(
        localStorage.getItem("questionLength", questionLength)
      );
    }

    const getLatestQuestion = questions.sort((a, b) =>
      a?.askTime > b?.askTime ? 1 : -1
    );

    if (isMountedRef.current) {
      localStorage.setItem("questionLength", questionLength);
      isMountedRef.current = false;
    } else if (questionLength !== storedLength) {
      setLatestQuestion(getLatestQuestion[questionLength - 1]);

      localStorage.setItem("questionLength", questionLength);
      setTimeout(() => {
        setShowNotif(true);
        setNotifAnimation("bounce-in");
        setTimeout(() => {
          setNotifAnimation("bounce-out");
          setTimeout(() => {
            setShowNotif(false);
          }, 500);
        }, 2000);
      }, 2000);
    }
  });

  useSignalREventListener(
    connection,
    (id, eventName, status, message, presenterConnectionId) => {
      const event = eventName?.toLowerCase();
      const msg = message?.toLowerCase();
      console.log(event, msg, "eventname");

      if (event === "pausepresentation" && msg === "presentation paused") {
        toast.warning("Presentation has been paused");
        if (isLeave) {
          navigate(-1);
          EndSignalRConnection();
        }
      }
    }
  );

  //usestate
  const [isLeave, setIsLeave] = useState(false);
  const [showNotif, setShowNotif] = useState(false);
  const [friendlyId, setFriendlyId] = useState(null);
  const [viewersCount, setViewersCount] = useState(0);
  const [questionCount, setQuestionCount] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [notifAnimation, setNotifAnimation] = useState("");
  const [latestQuestion, setLatestQuestion] = useState(null);
  const [openPollDrawer, setOpenPollDrawer] = useState(false);
  const [openScheduleModal, setOpenScheduleModal] = useState(false);
  const [openViewersDrawer, setOpenViewersDrawer] = useState(false);
  const [openQuestionsDrawer, setOpenQuestionsDrawer] = useState(false);
  const [startPresentationModal, setStartPresentationModal] = useState(false);
  const [openMoreOptionsDropdown, setOpenMoreOptionsDropdown] = useState(false);

  useEffect(() => {
    if (presentationDetails.data) {
      setFriendlyId(presentationDetails.data?.presentation?.friendlyId);
    }
  }, [presentationDetails]);

  useEffect(() => {
    if (connection) {
      GetQuestions(connection, presentationId);
    }
  }, [presentationId, connection, openQuestionsDrawer, connectionState]);

  useEffect(() => {
    GetUsersList(connection, presentationId);
  }, [connection, connectionState, presentationId, openViewersDrawer]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        moreDropdownRef.current &&
        !moreDropdownRef.current.contains(event.target)
      ) {
        setOpenMoreOptionsDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  //function
  const handleAttentionCancel = () => {
    setStartPresentationModal(false);
  };

  const openEndPresentationModal = () => {
    setIsModalOpen(true);
  };
  const onClose = () => {
    setOpenViewersDrawer(false);
  };

  const closeQuestionsDrawer = () => {
    setOpenQuestionsDrawer(false);
  };

  const closePollDrawer = () => {
    setOpenPollDrawer(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleCloseModal = () => {
    // addGuestModalRef.current.resetModal();
    setOpenScheduleModal(false);

    scheduleModalRef.current.resetModal();
  };

  const shareLink = async () => {
    let routeOrigin = window.location.origin;
    let text = `${routeOrigin}/join-presentation/${friendlyId}`;

    try {
      await navigator.clipboard.writeText(text);

      toast.success("Link copied to clipboard");
    } catch (error) {

      toast.error("Failed to copy link");
    }
  };
  //variables
  const moreOptions = [
    {
      label: "Add Guest",
      icon: outlinePlay,
      func: () => {
        setOpenScheduleModal(true);
        scheduleModalRef.current?.openDrawer();
      },
    },

    {
      label: "Copy Presentation Link",
      icon: link,
      func: () => shareLink(),
    },
  ];

  //function
  const pausePresentation = () => {
    setIsLeave(false);
    if (connectionStatus === "connected") {
      connection.invoke("PausePresentation", presentationId);
      setIsPresentationActive("paused");
    }
  };

  const leavePresentation = () => {
    if (isPresentationActive === "idle") {
      navigate(-1);
    } else {
      openEndPresentationModal();
    }

    // setIsLeave(true);
    // if (
    //   connectionStatus === "connected" &&
    //   connection &&
    //   isPresentationActive === "started"
    // ) {
    //   connection.invoke("PausePresentation", presentationId);
    // } else {
    //   navigate(-1);
    // }
  };

  return (
    <>
      <nav className="fixed p-ripple top-0 z-30 w-full h-[4rem] py-2 bg-white border-b ">
        <section className="flex ">
          <div className="px-6 py-3 border-r">
            <i
              className="font-bold cursor-pointer pi pi-chevron-circle-left"
              onClick={leavePresentation}
            ></i>
          </div>

          <FlexContainer extraClass="px-4 w-full items-center">
            <section className="flex items-center gap-x-2">
              <h2 className="font-bold font-grotesk">
                {presentationDetails?.data?.presentation?.title ??
                  "Presenta slide"}
              </h2>
              <div className="flex items-center text-sm font-semibold gap-x-1 text-primary">
                <img src={globe} alt="change status" />
                <p>Public</p>
              </div>
              {/* status bar  */}
              <section>
                <SignalRStatusBar />
              </section>
            </section>

            <section className="flex items-center gap-x-3">
              <Timer />

              {/* more options */}
              <div className="flex gap-x-4">
                <div className="relative">
                  <div
                    onClick={() =>
                      setOpenMoreOptionsDropdown(!openMoreOptionsDropdown)
                    }
                    className="flex items-center justify-center w-8 h-8 border rounded-md cursor-pointer bg-gray20"
                  >
                    <img src={settings} alt="settings" className="w-4" />
                  </div>

                  <div className="absolute right-0 top-10">
                    {openMoreOptionsDropdown && (
                      <div
                        ref={moreDropdownRef}
                        className="z-40 flex flex-col px-3 py-4 bg-white rounded-lg shadow w-52 gap-y-4"
                      >
                        {moreOptions?.map((option, index) => {
                          return (
                            <div
                              className="flex cursor-pointer gap-x-2"
                              key={index}
                              onClick={option?.func}
                            >
                              {option?.label === "Add Guest" ? (
                                <i
                                  className="pi pi-user-plus"
                                  style={{ fontSize: "1.1rem" }}
                                ></i>
                              ) : (
                                <img
                                  src={option.icon}
                                  alt={option.label}
                                  className="w-5"
                                />
                              )}

                              <p className="text-sm font-medium">
                                {option.label}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>

                {/* poll */}

                {/* <div
                  onClick={() => setOpenPollDrawer(!openPollDrawer)}
                  className="flex items-center justify-center rounded-md cursor-pointer h-9 w-9 bg-gray30"
                >
                  <img src={poll} alt="settings" className="w-4" />
                </div> */}
                {/*  */}
              </div>
              {/*  */}

              <div
                className="relative flex px-4 py-2 text-xs rounded-lg cursor-pointer gap-x-2 bg-gray30 text-gray500"
                onClick={() => setOpenViewersDrawer(true)}
              >
                <img src={people} alt="animate" className="w-5" />
                <p className="absolute top-1 px-[5px] py-[1px] text-[0.625rem]  font-bold text-black rounded-full right-2 bg-gray350 text-center flex items-center justify-center">
                  {viewersCount}
                </p>
              </div>

              <div
                className="relative flex px-4 py-2 text-xs rounded-lg cursor-pointer gap-x-2 bg-gray30 text-gray500"
                onClick={() => setOpenQuestionsDrawer(true)}
              >
                <img src={messagenotif} alt="animate" className="w-5" />

                <p className="absolute top-1 px-[5px] py-[1px] text-[0.625rem] font-bold text-white rounded-full right-2 bg-red-500 text-center flex items-center justify-center">
                  {questionCount}
                </p>
              </div>

              {/* presentation controller  */}
              {connection?.state === "Connected" &&
                presentationDetails.data && (
                  <section className="flex items-center gap-x-2">
                    {isPresentationActive !== "started" &&
                      isPresentationActive !== "paused" && (
                        <FlexContainer
                          extraClass=" font-semibold border border-primary text-primary cursor-pointer rounded-lg   text-xs px-4 py-2 gap-x-1"
                          disabled={connection}
                          onClick={beginPresentation}
                          // onClick={() => setIsModalOpen(true)}
                        >
                          {!connection || isPresentationActive === "loading" ? (
                            <ClipLoader size={12} color="orange" />
                          ) : (
                            <>
                              <i className="text-[10px] pi pi-play text-primary"></i>

                              <p>
                                {presentationDetails?.data?.presentation
                                  ?.status === 3 ||
                                presentationDetails?.data?.presentation
                                  ?.status === 2
                                  ? "Continue "
                                  : "Begin"}{" "}
                                presentation
                              </p>
                            </>
                          )}
                        </FlexContainer>
                      )}

                    {isPresentationActive === "paused" &&
                      isPresentationActive !== "started" && (
                        <FlexContainer
                          extraClass=" font-semibold border border-primary text-primary cursor-pointer rounded-lg   text-xs px-4 py-2 gap-x-1"
                          onClick={beginPresentation}
                        >
                          {isPresentationActive === "loading" ? (
                            <ClipLoader size={12} color="orange" />
                          ) : (
                            <>
                              <i className="text-[10px] pi pi-play text-primary"></i>
                              <p>Continue presentation</p>
                            </>
                          )}
                        </FlexContainer>
                      )}

                    {isPresentationActive === "started" && (
                      <FlexContainer
                        extraClass=" font-semibold border border-primary text-primary cursor-pointer rounded-lg   text-xs px-4 py-2 gap-x-1"
                        onClick={pausePresentation}
                      >
                        {isPresentationActive === "loading" ? (
                          <ClipLoader size={12} color="orange" />
                        ) : (
                          <>
                            <i className="text-[10px] pi pi-pause text-primary"></i>
                            <p>Pause presentation</p>
                          </>
                        )}
                      </FlexContainer>
                    )}

                    {(isPresentationActive === "started" ||
                      isPresentationActive === "paused") && (
                      <FlexContainer
                        extraClass="bg-primary cursor-pointer rounded-lg  text-white text-xs px-4 py-2 gap-x-1"
                        onClick={openEndPresentationModal}
                      >
                        {isPresentationActive === "loading" ? (
                          <ClipLoader size={12} color="white" />
                        ) : (
                          <>
                            <img src={stop} alt="play slide" className="w-3" />
                            <p>End presentation</p>
                          </>
                        )}
                      </FlexContainer>
                    )}
                  </section>
                )}
              {/*  */}
            </section>
          </FlexContainer>
        </section>

        {/* question notifivation  */}
        {showNotif && (
          <div
            className={`fixed top-8 right-16 w-auto bg-black rounded-xl text-white px-6 py-4 text-sm flex gap-x-3  text-center ${notifAnimation}`}
          >
            <i
              className="text-white pi pi-comments "
              style={{ fontSize: "1rem" }}
            ></i>

            <p>
              {" "}
              <span className="mr-1">{latestQuestion?.userId}</span> just asked
              a question
            </p>
          </div>
        )}
        {/*  */}
      </nav>

      <>
        {/* Drawer */}
        <>
          <Drawer
            title={`Viewers (${viewersCount})`}
            onClose={onClose}
            width={490}
            open={openViewersDrawer}
          >
            <AllViewers
              connection={connection}
              isPresentationActive={isPresentationActive}
            />
          </Drawer>

          <Drawer
            open={openQuestionsDrawer}
            onClose={closeQuestionsDrawer}
            width="30%"
            title={`Questions (${questionCount})`}
          >
            <AllQuestions
              connection={connection}
              presentationId={presentationId}
            />
          </Drawer>

          <Drawer
            title={`Create Poll`}
            onClose={closePollDrawer}
            open={openPollDrawer}
          >
            <PollDrawer
              connection={connection}
              presentationId={presentationId}
            />
          </Drawer>
        </>
        {/*  */}

        {/* Modal */}
        <Modal open={isModalOpen} onCancel={handleCancel} footer={null}>
          <EndPresentations
            endPresentation={endPresentation}
            connection={connection}
            closeModal={handleCancel}
          />
        </Modal>

        {/* Modal */}

        <Modal
          open={startPresentationModal}
          onCancel={handleAttentionCancel}
          footer={null}
          // width={400}
          maskClosable={false}
        >
          <StartPresentation closeModal={handleAttentionCancel} />
        </Modal>

        {/* Drawer */}
        <>
          <Drawer
            height="94%"
            closable={false}
            placement="bottom"
            open={openScheduleModal}
            onClose={handleCloseModal}
            bodyStyle={{ padding: "0px" }}
          >
            <SchedulePresentation
              shareLink={shareLink}
              ref={scheduleModalRef}
              scheduledTime={null}
              closeModal={handleCloseModal}
              presentationId={presentationId}
            />
          </Drawer>
        </>

        {/* <Modal
          open={openScheduleModal}
          onCancel={handleCloseModal}
          footer={null}
          width={900}
          centered
        >
          <AddGuest
            ref={addGuestModalRef}
            closeModal={handleCloseModal}
            presentationId={presentationId}
          />
        </Modal> */}
        {/*  */}
        {/*  */}
      </>
    </>
  );
}

import React, { useState } from 'react';

// Assets
import ellipsis from 'assets/svg/ellipsis-horizontal.svg';
import eyeIcon from 'assets/svg/eye.svg';
// import toggleBlack from 'assets/svg/_Toggle-base-black.svg';
// import toggleWhite from 'assets/svg/_Toggle-base-white.svg';
import copyIcon from 'assets/svg/copy-1.svg';
import eye2 from 'assets/svg/eye-2.svg';

// Libraries
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

export default function AuditTrail({
  briefcaseDocuments,
}: {
  briefcaseDocuments: any;
}) {
  //Types
  //   interface RowData {
  //     name: string;
  //     action: string;
  //     date: string;
  //   }

  // States

  const files = [
    {
      id: '1',
      name: 'Tominwa',
      action: 'Created thepresenta.com',
      date: '30 mins ago',
    },
    {
      id: '1',
      name: 'Tominwa',
      action: 'Created thepresenta.com',
      date: '30 mins ago',
    },
    {
      id: '1',
      name: 'Tominwa',
      action: 'Created thepresenta.com',
      date: '30 mins ago',
    },
    {
      id: '1',
      name: 'Tominwa',
      action: 'Created thepresenta.com',
      date: '30 mins ago',
    },
    {
      id: '1',
      name: 'Tominwa',
      action: 'Created thepresenta.com',
      date: '30 mins ago',
    },
    {
      id: '1',
      name: 'Tominwa',
      action: 'Created thepresenta.com',
      date: '30 mins ago',
    },
    {
      id: '1',
      name: 'Tominwa',
      action: 'Created thepresenta.com',
      date: '30 mins ago',
    },
    {
      id: '1',
      name: 'Tominwa',
      action: 'Created thepresenta.com',
      date: '30 mins ago',
    },
    {
      id: '1',
      name: 'Tominwa',
      action: 'Created thepresenta.com',
      date: '30 mins ago',
    },
    {
      id: '1',
      name: 'Tominwa',
      action: 'Created thepresenta.com',
      date: '30 mins ago',
    },
    {
      id: '1',
      name: 'Tominwa',
      action: 'Created thepresenta.com',
      date: '30 mins ago',
    },
  ];

  const columns = [
    {
      header: 'Name',
      field: 'name',
    },
    {
      header: 'Action',
      field: 'action',
    },
    {
      header: 'Date',
      field: 'date',
    },
  ];

  return (
    <div>
      {/* Table */}
      <section className='overflow-hidden rounded-xl w-full'>
        <DataTable
          value={files}
          tableStyle={{ minWidth: '45rem', maxHeight: '300px' }}
          size={'normal'}
        >
          {columns.map((col, index) => {
            return (
              <Column
                key={index}
                // body={col?.body}
                field={col.field}
                header={col.header}
                headerClassName='bg-+[#F5F5F5F5] text-blue300 font-normal text-sm'
                className='text-sm'
              />
            );
          })}
        </DataTable>
      </section>
    </div>
  );
}

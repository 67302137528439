import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//assets
import upload from "assets/svg/uploadDark.svg";

//slices
import {
  createFolder,
  getFolderFiles,
  getWorkspaceFolders,
} from "store/Workspace/workspaceSlice";

//libraries
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import { ProgressBar } from "primereact/progressbar";

//components
import Label from "components/UI/Label";
import AuthInput from "components/UI/Inputs/AuthInput";
import { getFileStructureState } from "store/FileStructure/fileStructureSlice";

export default function CreateFolder({ closeModal }) {
  const dispatch = useDispatch();

  //useselector
  const { personalWorkspaceId, mostActiveFolder } = useSelector(
    getFileStructureState
  );

  //useref
  const folderName = useRef();

  //usestate
  const [pptFile, setPptFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isButtonActive, setIsButtonActive] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);

  //useeffects
  useEffect(() => {
    console.log(folderName?.current?.value);
  }, [folderName]);

  //functions

  const handleChange = (e) => {
    e?.target?.value && setIsButtonActive(true);
  };

  const createFolderFunc = () => {
    const folderId = mostActiveFolder?.id;
    const title = folderName?.current?.value;
    const workspaceId = personalWorkspaceId?.workspaceId;

    if (!title) {
      toast.error("Enter folder's name");
      return;
    }

    setIsLoading(true);
    setIsButtonActive(false);

    //monitor upload progress
    const config = {
      onUploadProgress: (progressEvent) => {
        const progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );

        setUploadProgress(progress);
        if (progress === 100) {
          setUploadComplete(true);
        } else {
          setUploadComplete(false);
        }
      },
    };

    const data = {
      title,
      folderId,
      workspaceId,
      file: pptFile,
    };

    console.log(data, "hereeee");

    //dispatch function
    dispatch(createFolder({ data, config })).then(({ payload }) => {
      setIsLoading(false);
      setIsButtonActive(true);
      if (payload) {
        toast.success("Creation successful");
        setPptFile(null);
        closeModal();
        workspaceId && dispatch(getWorkspaceFolders(workspaceId));
        workspaceId && folderId && dispatch(getFolderFiles(folderId));
        folderName.current.value = "";
        // dispatch(openPollDrawer(payload?.docId));
      }
    });
    //
  };
  return (
    <section className="">
      <div className="mt-[-10px]">
        <img src={upload} alt="upload slide" />
      </div>

      <section className="w-[93%] mx-auto my-2 font-grotesk">
        <div>
          <h1 className="text-2xl font-bold">Create Folder</h1>
        </div>

        <div className="my-4">
          <Label text="Folder name" />
          <AuthInput
            type="text"
            name="text"
            customRef={folderName}
            placeholder="Folder name"
            onChange={(e) => handleChange(e)}
          />
        </div>
        {/* 
        <div
          draggable
          onDrop={(e) => drop(e)}
          onDragOver={(e) => dragOver(e)}
          className="my-6 border-2 border-dashed rounded-lg cursor-pointer bg-gray20"
          onClick={openUploadModal}
        >
          {!pptFile && (
            <div className="flex flex-col items-center py-4 text-center">
              <p>
                {" "}
                <span className="font-semibold">Click to upload</span> or drag
                and drop
              </p>
              <p>Powerpoint, JPG or PDF </p>
            </div>
          )}

          {pptFile && (
            <div className="py-4">
              <img src={pptImg} alt="ppt file" className="w-10 mx-auto" />
              <p className="py-2 text-center">{pptFile?.name}</p>
            </div>
          )}

          <input
            hidden
            type="file"
            ref={uploadFileRef}
            onChange={(e) => storeUploadedFile(e)}
          />
        </div> */}

        {(uploadProgress < 1 || uploadComplete) && (
          <button
            disabled={!isButtonActive}
            onClick={createFolderFunc}
            className={`flex items-center justify-center w-full py-3 text-sm text-white rounded-lg cursor-pointer bg-primary gap-x-2 ${
              isButtonActive ? "opacity-100" : "opacity-50 cursor-not-allowed"
            }`}
          >
            {isLoading && !uploadComplete && (
              <ClipLoader size={15} color="white" />
            )}

            {isLoading && uploadProgress === 100 && (
              <div className="flex items-center gap-x-1">
                <p>Processing</p>
                <ClipLoader size={15} color="white" />
              </div>
            )}
            {!isLoading && (
              <>
                {/* <img src={uploadWhite} alt="upload slide" className="w-4" /> */}
                <p>Create</p>
              </>
            )}
          </button>
        )}

        {uploadProgress > 0 && !uploadComplete && (
          <ProgressBar
            color="#FF692990"
            label="Uploading"
            value={uploadProgress}
            className="h-8 rounded-lg"
          ></ProgressBar>
        )}

        {}
      </section>
    </section>
  );
}

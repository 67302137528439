import React, { useEffect, useState } from "react";
import NavigationTab from "components/Admin/Presentation/PresentationDetails/NavigationTab";
import Navbar_ from "components/UI/AdminLayout/Navbar_";
import shareButtonIcon from "assets/svg/shareButtonIcon.svg";
import { CButton } from "components/UI/Button/Buttons";
import threeDot from "assets/svg/threeDot.svg";
import { BannerImageWrapper } from "components/UI/wrapper/banner-image-wrapper";
import calendar from "assets/svg/calendar.svg";
import peopleGroup from "assets/svg/peopleGroup.svg";
import questionMark from "assets/svg/questionMarkRounded.svg";
import timerGrey from "assets/svg/timerGrey.svg";
import CTab from "components/UI/Tabs";
import { PresentationTable } from "components/UI/Tables/presentation-tables/presentation-table";
import { AnalyticsCard } from "components/UI/Cards/analytics-card";
import { Col, Row } from "antd";
import profileAdd from "assets/svg/profile-add.svg";
import timerNew from "assets/svg/timerNew.svg";
import downloadBroken from "assets/svg/downloadBroken.svg";
import { SpaceMetricsCard } from "components/UI/Cards/space-metrics-card";
import graphIcon from "assets/svg/graphIcon.svg";
import ellipsisCovered from "assets/svg/ellipsisCovered.svg";
import placeHolderChart from "assets/svg/placeHolderChart.svg";
import ellipsisVertical from "assets/svg/ellipsis-vertical.svg";
import linePlaceHolder from "assets/svg/lineBarChartPlaceholder.svg";
import { VisitorTable } from "components/UI/Tables/presentation-tables/visitor-table";
import { useDispatch, useSelector } from "react-redux";
import {
  getPresentationDetails,
  getPresentationMetrics,
  getPresentationPerformanceSummary,
  getPresentationPlaylist,
  getPresentationSlideMetrics,
  getPresentationState,
  getPresentationVisitors,
} from "store/Presentations/presentationSlice";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { TableLoading } from "components/UI/States/table/TableLoading";
import { TableError } from "components/UI/States/table/TableError";
import { TableEmpty } from "components/UI/States/table/TableEmpty";
import {
  InnerPresentationInterface,
  PresentationAttendanceInterface,
  PresentationDataInterface,
  PresentationMetricsInterface,
  PresentationPerformanceSummaryInterface,
  PresentationPlaylistInterface,
  PresentationSlideMetricsInterface,
} from "interfaces/interfaces-data";
import { Chart } from "primereact/chart";
import "primereact/resources/themes/saga-blue/theme.css"; // Optional, for a PrimeReact theme
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { LineChart } from "components/UI/Charts/LineChart";
import StackedBarChart from "components/UI/Charts/StackedBarChart";
import { RootState } from "store/store";
import { DateFromNow } from "utils/formatDate";
import { imageStream } from "utils/imageStream";

const PresentationDetails_ = () => {
  // Line Chart Data

  // Line chart Data

  const [backgroundImage, setBackgroundImage] = useState("");

  const tabItem = ["Presentation playlists", "Analytics", "Polls", "Visitors"];
  const [active, setActive] = useState(0);
  const [presentationId, setpresentationId] = useState("");
  const { presentationData } = useSelector(
    (state: RootState) => state.presentationDSlice
  );
  const { presentationPlaylist } = useSelector((state) =>
    getPresentationState(state)
  ) as {
    presentationPlaylist: {
      isLoading: boolean;
      data?: PresentationPlaylistInterface[];
    };
  };
  const { presentationMetrics } = useSelector((state) =>
    getPresentationState(state)
  ) as {
    presentationMetrics: {
      isLoading: boolean;
      data?: PresentationMetricsInterface;
    };
  };

  const { presentationVisitors } = useSelector((state) =>
    getPresentationState(state)
  ) as {
    presentationVisitors: {
      isLoading: boolean;
      data?: PresentationAttendanceInterface[];
    };
  };

  const { presentationSlideMetrics } = useSelector((state) =>
    getPresentationState(state)
  ) as {
    presentationSlideMetrics: {
      isLoading: boolean;
      data?: PresentationSlideMetricsInterface[];
    };
  };

  const { presentationPerformanceSummary } = useSelector((state) =>
    getPresentationState(state)
  ) as {
    presentationPerformanceSummary: {
      isLoading: boolean;
      data?: PresentationPerformanceSummaryInterface[];
    };
  };

  const { presentationDetails } = useSelector((state) =>
    getPresentationState(state)
  ) as {
    presentationDetails: {
      isLoading: boolean;
      data?: InnerPresentationInterface;
    };
  };

  // const {presenta}

  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const getStream = async (id: string) => {
    const result = await imageStream(id);
    setBackgroundImage(result ?? "");
  };

  useEffect(() => {
    let url = new URLSearchParams(window.location.search);
    const presentationId = url.get("q") ?? "";
    setpresentationId(presentationId);
    if (presentationData.thumbnail) {
      getStream(presentationData.thumbnail);
    }
    dispatch((getPresentationPlaylist as (id: string) => any)(presentationId));
    dispatch((getPresentationMetrics as (id: string) => any)(presentationId));
    dispatch(
      (getPresentationSlideMetrics as (id: string) => any)(presentationId)
    );
    dispatch((getPresentationVisitors as (id: string) => any)(presentationId));
    dispatch(
      (getPresentationPerformanceSummary as (id: string) => any)(presentationId)
    );
    dispatch((getPresentationDetails as (id: string) => any)(presentationId));
  }, [dispatch]);

  useEffect(() => {
    if (
      presentationDetails.data?.presentation.id &&
      !presentationData.thumbnail
    ) {
      getStream(presentationDetails.data?.presentation.thumbnail);
    }
  }, [presentationDetails]);

  return (
    <section>
      <section className="relative">
        <Navbar_
          hasBreadCrumb
          leftContent={
            <div className="flex items-center gap-2">
              <span className="border  border-[#EBEBEB] cursor-pointer px-4 rounded-lg py-3 bg-[#FAFAFA] ">
                <img src={threeDot} />
              </span>
              <CButton
                loading={false}
                text="Share Link"
                icon={shareButtonIcon}
                variant="plain"
              />
            </div>
          }
          subText={[
            { link: "/admin/mypresentations", text: "Presentations" },
            {
              link: `/admin/presentation-details_?q=${presentationId}`,
              text: "details",
            },
          ]}
          mainText="My Presentation Details"
        />
      </section>
      <section className="mt-4">
        <BannerImageWrapper
          handleSetBannerImage={setBackgroundImage}
          bgImage={backgroundImage}
          id={presentationDetails.data?.presentation.id ?? ""}
        >
          <div>
            <p className="text-lg font-semibold">
              {presentationData.title
                ? presentationData.title
                : presentationDetails.data?.presentation.title}
            </p>
            <div className="flex items-center gap-4 my-2 mb-4">
              <BannerTextWrapper
                text={`Created ${
                  presentationData.dateAdded
                    ? DateFromNow(presentationData.dateAdded)
                    : DateFromNow(
                        presentationDetails.data?.presentation.dateAdded
                      )
                } `}
                icon={calendar}
              />
              <BannerTextWrapper
                text={`${
                  presentationData.visitors
                    ? presentationData.visitors
                    : presentationDetails.data?.presentation.visitors
                } visitors`}
                icon={peopleGroup}
              />
              <BannerTextWrapper
                text={`${
                  presentationData.questions
                    ? presentationData.questions
                    : presentationDetails.data?.presentation.questions
                } question(s)`}
                icon={questionMark}
              />
              <BannerTextWrapper text={`Last Visit -`} icon={timerGrey} />
            </div>
            <span>{/* <img src={avatar} /> */}</span>
          </div>
        </BannerImageWrapper>
      </section>
      <section>
        <CTab active={active} setActive={setActive} tabList={tabItem} />
        <div className="my-4">
          {active == 0 ? (
            presentationPlaylist.isLoading ? (
              <TableLoading />
            ) : !presentationPlaylist.data?.length ? (
              <TableEmpty />
            ) : !presentationPlaylist.data &&
              !presentationPlaylist.isLoading ? (
              <TableError />
            ) : (
              <PresentationTable playList={presentationPlaylist.data} />
            )
          ) : active == 1 ? (
            <div>
              <Row gutter={12}>
                <Col xs={4}>
                  <AnalyticsCard
                    icon={profileAdd}
                    text="Total invited"
                    statValue={presentationMetrics.data?.invited ?? 0}
                  />
                </Col>
                <Col xs={4}>
                  <AnalyticsCard
                    icon={peopleGroup}
                    text="Total attendance"
                    statValue={presentationMetrics.data?.attendance ?? 0}
                  />
                </Col>
                <Col xs={4}>
                  <AnalyticsCard
                    icon={timerNew}
                    text="Presentation minute"
                    statValue={
                      presentationMetrics.data?.presentationDuration ?? 0
                    }
                  />
                </Col>
                <Col xs={4}>
                  <AnalyticsCard
                    icon={downloadBroken}
                    text="Number of downloads"
                    statValue={presentationMetrics.data?.downloads ?? 0}
                  />
                </Col>
                <Col xs={8}>
                  <SpaceMetricsCard
                    val_1={presentationMetrics.data?.attendance ?? 0}
                    val_2={presentationMetrics.data?.pollResponse ?? 0}
                  />
                </Col>
              </Row>
              <Row gutter={12} className="mt-8">
                <Col xs={12}>
                  <div className="border border-[#E9EBF0] bg-white rounded-lg p-4">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-2">
                        <span>
                          <img src={graphIcon} alt="" />
                        </span>
                        <p className="text-base font-semibold">
                          Time spent per slide
                        </p>
                      </div>
                      <span>
                        <img src={ellipsisCovered} />
                      </span>
                    </div>
                    <div className="mt-4">
                      {presentationSlideMetrics.isLoading ? (
                        "Loading..."
                      ) : presentationSlideMetrics.data ? (
                        // <LineChart
                        //   labels={[
                        //     "slide_1",
                        //     "slide_2",
                        //     "slide_3",
                        //     "slide_4",
                        //     "slide_5",
                        //     "slide_6",
                        //     "slide_6",
                        //     "slide_6",
                        //   ]}
                        //   data={[3, 4, 5, 4, 3, 5, 0, 2]}
                        // />
                        <LineChart
                          labels={presentationSlideMetrics.data?.map((item) => {
                            return item.title.slice(0, 5);
                          })}
                          data={presentationSlideMetrics.data.map((item) => {
                            return item.score;
                          })}
                        />
                      ) : (
                        "no chart data found"
                      )}
                    </div>
                  </div>
                </Col>
                <Col xs={12}>
                  <div className="border border-[#E9EBF0] bg-white rounded-lg p-4">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-2">
                        <span>
                          <img src={graphIcon} alt="" />
                        </span>
                        <p className="text-base font-semibold">
                          Drop off statistics
                        </p>
                      </div>
                      <span>
                        <img src={ellipsisCovered} />
                      </span>
                    </div>
                    <div className="mt-4">
                      <img src={placeHolderChart} className="w-full" />
                    </div>
                  </div>
                </Col>
                <Col className="mt-4" xs={24}>
                  <div className="border border-[#EBF1FF] rounded-lg p-4">
                    {" "}
                    <div className="flex pb-4 border-b items-center justify-between">
                      <p className="font-semibold text-base">
                        Performance Summary
                      </p>
                      <span>
                        <img src={ellipsisVertical} alt="" />
                      </span>
                    </div>
                    <div className="mt-4">
                      {/* <img src={linePlaceHolder} alt="" className="w-full" /> */}
                      {presentationPerformanceSummary.isLoading ? (
                        "loading"
                      ) : presentationPerformanceSummary.data ? (
                        <StackedBarChart
                          dataOne={presentationPerformanceSummary.data.map(
                            (item) => item.downloads
                          )}
                          dataTwo={presentationPerformanceSummary.data.map(
                            (item) => item.attention
                          )}
                          label={presentationPerformanceSummary.data.map(
                            (item) => item.title
                          )}
                          overallPayload={presentationPerformanceSummary.data}
                        />
                      ) : (
                        "No data found"
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          ) : active == 2 ? (
            ""
          ) : active == 3 ? (
            presentationVisitors.isLoading ? (
              <TableLoading />
            ) : !presentationVisitors.isLoading &&
              !presentationVisitors.data ? (
              <TableError />
            ) : !presentationVisitors.data?.length ? (
              <TableEmpty />
            ) : (
              <VisitorTable presentationVistor={presentationVisitors.data} />
            )
          ) : (
            ""
          )}
        </div>
      </section>
    </section>
  );
};

const BannerTextWrapper = ({ icon, text }: { icon: string; text: string }) => {
  return (
    <div className="flex items-center gap-1">
      <span>
        <img src={icon} alt="" />
      </span>
      <p className="text-[#545454] text-sm">{text}</p>
    </div>
  );
};
export default PresentationDetails_;

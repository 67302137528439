/* eslint-disable array-callback-return */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

//assets
import dots from "assets/svg/dots.svg";
import lock from "assets/svg/lock.svg";
// import link from "assets/svg/link.svg";
import trash from "assets/svg/trash.svg";
import noImg from "assets/image/noImg.png";
// import access from "assets/svg/access.svg";
import rename from "assets/svg/rename.svg";
// import details from "assets/svg/details.svg";
import metrics from "assets/svg/metrics.svg";
import publicImg from "assets/svg/public.svg";
import updateThumb from "assets/svg/updateThumb.svg";

import faqImg from "assets/svg/faq.svg";
import profileImg from "assets/svg/profile-2user.svg";
import timerImg from "assets/svg/timer1.svg";

//libraries
// import moment from "moment";
import { Dropdown, Menu, Modal, Tooltip } from "antd";

//components
import ConfirmationModal from "components/UI/Modal/ConfirmationModal";

//slices
import {
  deletePresentation,
  getAllPresentations,
} from "store/Presentations/presentationSlice";
import { imageStream } from "utils/imageStream";
import { ClipLoader } from "react-spinners";

//components
// import PresentationStatusTag from "./PresentationStatusTag";
import EditPresentation from "views/Admin/Presentation/Modals/EditPresentation";
import { trimText } from "utils/formatter";
import { setThumbnail } from "store/Slides/slidesSlice";
import { useToast } from "components/UI/ToastProvider";
import { setPresentationDSlice } from "../../../../../store/generics/presenationDSlice";
import { toast } from "sonner";

function PresentationCard({ presentation }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    id,
    title,
    // startTime,
    // scheduledTime,
    visibility,
    thumbnail,
    status,
    visitors,
    questions,
    duration,
  } = presentation ?? {};

  //useref
  const editModalRef = useRef();
  const uploadFileRef = useRef();

  //usestate
  const [tagDetails, setTagDetails] = useState();
  const [noServerImg, setNoServerImg] = useState(false);
  const [thumbnailImg, setThumbnailImg] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [thumbnailLoader, setThumbnailLoader] = useState(false);
  const [editPresentationModal, setEditPresentationModal] = useState(false);

  //useeffects

  useEffect(() => {
    const fetchImageStream = async () => {
      if (!thumbnail || thumbnail?.includes("//")) {
        setThumbnailImg(noImg);
        setNoServerImg(true);
      } else {
        const url = await imageStream(thumbnail);
        setThumbnailImg(url);
      }
    };
    fetchImageStream();
  }, [thumbnail]);

  //function
  const closeConfirmDelete = () => {
    setConfirmDelete(false);
  };

  const handleAttentionCancel = () => {
    setEditPresentationModal(false);
    editModalRef.current.resetModal();
  };

  const convertTime = (time) => {
    let finalText;

    if (time >= 3600) {
      const hours = Math.floor(time / 3600);
      const remainingMinutes = Math.floor((time % 3600) / 60);
      finalText = `${hours}hr${hours > 1 ? "s" : ""}${
        remainingMinutes > 0
          ? ` ${remainingMinutes}min${remainingMinutes > 1 ? "s" : ""}`
          : ""
      }`;
    } else if (time >= 60) {
      const minutes = Math.floor(time / 60);
      const remainingSeconds = time % 60;
      finalText = `${minutes}min${minutes > 1 ? "s" : ""}${
        remainingSeconds > 0
          ? ` ${remainingSeconds}sec${remainingSeconds > 1 ? "s" : ""}`
          : ""
      }`;
    } else {
      finalText = `${time > 0 ? time : 0}sec${time > 1 ? "s" : ""}`;
    }

    return finalText;
  };

  //requests
  const trashPresentation = () => {
    setIsDeleteLoading(true);
    dispatch(deletePresentation(id)).then(({ type }) => {
      setIsDeleteLoading(false);

      if (type.includes("fulfilled")) {
        closeConfirmDelete();
        toast.success("Presentation deleted successfully");

        dispatch(getAllPresentations());
      }
    });
  };

  const updateThumbnail = (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }

    // Store the current thumbnail before updating
    const previousThumbnailImg = thumbnailImg;

    // Optimistic UI update
    const objectUrl = URL.createObjectURL(file);
    setThumbnailImg(objectUrl);
    setNoServerImg(false);
    setThumbnailLoader(true);

    const data = {
      file,
      presentationId: presentation?.id,
    };

    // Clear the file input value to allow selecting the same file again
    if (e.target?.value) {
      e.target.value = null;
    }

    dispatch(setThumbnail(data)).then(({ type }) => {
      setThumbnailLoader(false);
      if (type.includes("fulfilled")) {
        toast.success("Thumbnail updated successfully");
      } else {
        // Handle error by reverting to the previous image
        setThumbnailImg(previousThumbnailImg);
        toast.error("Failed to update thumbnail, reverting to previous image");
      }
    });
  };

  //variables
  const items = [
    {
      key: "1",
      label: (
        <div
          className="flex gap-x-2"
          onClick={() => setEditPresentationModal(true)}
        >
          <img src={rename} alt="rename file" className="w-4" />
          <p>Rename</p>
        </div>
      ),
    },

    {
      key: "2",
      label: (
        <div
          className="flex gap-x-2"
          onClick={() => navigate(`/admin/presentation-metric/${id}`)}
        >
          <img src={metrics} alt="metrics file" className="w-4" />
          <p>Metrics</p>
        </div>
      ),
    },

    {
      key: "3",
      label: (
        <div
          className="flex gap-x-2"
          onClick={() => uploadFileRef.current.click()}
        >
          <img src={updateThumb} alt="metrics file" className="w-4" />
          <p>Update thumbnails</p>
        </div>
      ),
    },

    // {
    //   key: "1",
    //   label: (
    //     <div className="flex gap-x-2">
    //       <img src={details} alt="create new folder" className="w-4" />
    //       <p>Details</p>
    //     </div>
    //   ),
    //   // onClick: (file) => {
    //   //   setPresentationDoc(file?.id);
    //   //   setIsModalOpen(true);
    //   // },
    // },

    // {
    //   key: "4",
    //   label: (
    //     <div
    //       className="flex gap-x-2"
    //       // onClick={() => setIsFileUploadModalOpen(true)}
    //     >
    //       <img src={access} alt="access control" className="w-4" />
    //       <p>Access control</p>
    //     </div>
    //   ),
    // },

    // {
    //   key: "5",
    //   label: (
    //     <div
    //       className="flex gap-x-2"
    //       // onClick={() => setIsFileUploadModalOpen(true)}
    //     >
    //       <img src={link} alt="share file" className="w-4" />
    //       <p>Share</p>
    //     </div>
    //   ),
    // },

    {
      key: "6",
      label: (
        <div className="flex gap-x-2" onClick={() => setConfirmDelete(true)}>
          <img src={trash} alt="delete file" className="w-4" />
          <p>Delete</p>
        </div>
      ),
    },
  ];

  return (
    <section className="relative">
      <input
        hidden
        type="file"
        ref={uploadFileRef}
        onChange={updateThumbnail}
      />
      {/* <section className="absolute top-0 right-0 z-10">
        <PresentationStatusTag status={status} />
      </section> */}
      <Link
        to={
          status !== 4
            ? `/presentation-details/${presentation?.id}`
            : `/admin/presentation-details_?q=${presentation?.id}`
        }
      >
        <section
          onClick={() => {
            dispatch(setPresentationDSlice(presentation));
          }}
        >
          {thumbnailImg ? (
            <>
              {noServerImg ? (
                <div
                  className={` border border-[#F6F6F6]  bg-white md:w-[95%] lg:w-full lg:h-[230px] md:h-[200px] h-[180px] w-full rounded-lg  flex justify-center items-center `}
                >
                  <img
                    alt={title}
                    src={thumbnailImg}
                    className={`object-contain  w-[200px]   `}
                  />
                </div>
              ) : (
                <img
                  alt={title}
                  src={thumbnailImg}
                  className={` border border-[#F6F6F6]  lg:w-full md:w-[95%] lg:h-[230px] md:h-[200px] h-[180px] w-full rounded-lg  object-cover`}
                />
              )}
            </>
          ) : (
            <div
              className={`lg:w-full md:w-[95%] h-[230px] border-b-4 ${tagDetails} rounded-lg  shadow bg-white flex items-center justify-center `}
            >
              <ClipLoader size={12} color="gray" />
            </div>
          )}
        </section>
      </Link>
      <section className="flex items-center justify-between w-[95%]">
        <section className="my-2 font-grotesk">
          <div className="flex items-center mt-2 gap-x-1 ">
            <Tooltip placement="top" title={title}>
              <h3 className="text-lg font-semibold">{trimText(title, 40)}</h3>
            </Tooltip>

            {visibility ? (
              <img src={publicImg} alt=" public" />
            ) : (
              <img src={lock} alt="private" />
            )}
          </div>

          <section className="flex items-center mt-1 font-semibold gap-x-2 text-gray40">
            <div className="flex items-center gap-x-1">
              <img src={profileImg} alt="" className="w-4" />
              <p className="text-xs lg:text-sm">
                {visitors} visitor<small>(s)</small>
              </p>
            </div>

            <div className="flex items-center gap-x-1">
              <img src={timerImg} alt="" className="w-4" />
              <p className="text-xs lg:text-sm mt-[1px">
                {convertTime(duration)}
              </p>
            </div>

            {/* <div className="flex items-center gap-x-1">
              <img src={faqImg} alt="" className="w-4" />
              <p className="text-xs lg:text-sm">
                {questions} question<small>(s)</small>
              </p>
            </div> */}
          </section>
          {/* <p className="text-xs lg:text-sm">
            {moment(scheduledTime ?? startTime).format("DD MMM YYYY h:mmA")}
          </p> */}
        </section>

        {
          <>
            {thumbnailLoader && <ClipLoader size={15} color="gray" />}

            {!thumbnailLoader && (
              <section onClick={(e) => e.preventDefault()}>
                <Dropdown
                  trigger={["click"]}
                  dropdownRender={(menu) => (
                    <Menu>
                      {/* onClick={() => item?.onClick(file)} */}
                      {items.map((item) => {
                        if (
                          (status === 0 || status === 1) &&
                          item.key === "2"
                        ) {
                          return;
                        }
                        return (
                          <Menu.Item key={item.key}>{item.label}</Menu.Item>
                        );
                      })}
                    </Menu>
                  )}
                >
                  <div className="cursor-pointer">
                    <img src={dots} alt="options" />
                  </div>
                </Dropdown>
              </section>
            )}
          </>
        }
      </section>
      {/* Modal */}
      <ConfirmationModal
        open={confirmDelete}
        isLoading={isDeleteLoading}
        onClose={closeConfirmDelete}
        func={trashPresentation}
        text="Are you sure you want to delete this presentation?"
      />

      <Modal
        open={editPresentationModal}
        onCancel={handleAttentionCancel}
        footer={null}
        // width={400}
      >
        <EditPresentation
          ref={editModalRef}
          presentation={presentation}
          closeModal={handleAttentionCancel}
        />
      </Modal>
    </section>
  );
}
export default PresentationCard;

import { ThunkDispatch } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ClipLoader } from "react-spinners";
import { setThumbnail } from "store/Slides/slidesSlice";
import { imageStream } from "utils/imageStream";
import noImg from "assets/image/noImg.png";

export const ResolveTableImage = ({ id }: { id: string }) => {
  const [thumbNailImage, setThumbNailImage] = useState("");
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const getImageUrl = async () => {
    try {
      const result = await imageStream(id);
      setThumbNailImage(result!);
    } catch (e) {
      console.log("Error trying to get image");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getImageUrl();
  }, []);
  return (
    <div>
      {loading ? (
        <ClipLoader size={12} color="grey" />
      ) : thumbNailImage ? (
        <img src={thumbNailImage} className="w-[40px] rounded-lg h-[40px]" />
      ) : (
        <img src={noImg} className="w-[40px] rounded-lg h-[40px]" />
      )}
    </div>
  );
};

import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";
import { ActiveElement, ChartEvent } from "chart.js";
import { items } from "views/Authentication/Login/data";
import greenDot from "assets/svg/greenDot.svg";
import orangeDot from "assets/svg/orangeDot.svg";

export default function StackedBarChart({
  label,
  dataOne,
  dataTwo,
  overallPayload,
}: {
  label: string[];
  dataOne: number[];
  dataTwo: number[];
  overallPayload: any[];
}) {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const [data1, setData1] = useState<number[]>(dataOne);
  const [data2, setData2] = useState<number[]>(dataTwo);
  const [cLabel, setCLabel] = useState<string[]>(label);

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const textColorSecondary = documentStyle.getPropertyValue(
      "--text-color-secondary"
    );
    const surfaceBorder = documentStyle.getPropertyValue("--surface-border");
    const data = {
      labels: cLabel,
      datasets: [
        {
          type: "bar",
          label: "Dataset 1",
          backgroundColor: "  #FFBA9D",
          data: data1,
        },
        {
          type: "bar",
          label: "Dataset 2",
          backgroundColor: "#73C496",
          data: data2,
        },
      ],
    };
    const options = {
      maintainAspectRatio: false,
      onClick: (event: ChartEvent, elements: ActiveElement[]) => {
        if (elements.length > 0) {
          const datasetIndex = elements[0].datasetIndex;
          const dataIndex = elements[0].index;
          // Check if there is any data property from the data
          if (overallPayload[dataIndex]?.data?.length) {
            // Do Something
            const newData = overallPayload[dataIndex].data;
            console.log(newData, "New value for data heree");
            // get the downloads as it is the first index
            const cDataOne = newData.map((item: any) => item.value[0]);
            const cDataTwo = newData.map((item: any) => item.value[1]);
            const cLabel = newData.map((item: any) => item.key);
            setData1(cDataOne);
            setData2(cDataTwo);
            setCLabel(cLabel);
          } else {
            // Do nothing
          }
          // const datasetLabel = data.datasets[datasetIndex].label;
          // const clickedValue = data.datasets[datasetIndex].data[dataIndex];

          // console.log(
          //   `Clicked on dataset: ${datasetLabel}, label: ${label[dataIndex]}, value: ${clickedValue}`
          // );
        }
      },
      aspectRatio: 0.6,
      plugins: {
        tooltips: {
          mode: "index",
          intersect: false,
        },
        legend: {
          labels: {
            display: false,
            color: textColor,
          },
          display: false,
        },
        title: {
          display: false,
        },
      },
      scales: {
        xAxes: [
          {
            barPercentage: 0.4,
          },
        ],
        x: {
          stacked: true,
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: "black",
            display: false,
          },
        },

        y: {
          stacked: true,
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
          },
        },
      },
    };

    setChartData(data);
    setChartOptions(options);
  }, [data1, data2, cLabel]);

  return (
    <div className="card">
      <div className="flex justify-end gap-2">
        <div className="flex items-center gap-1">
          <span>
            <img src={orangeDot} />
          </span>
          <p className="text-[#475467] text-sm">Slide Downloads</p>
        </div>
        <div className="flex items-center gap-1">
          <span>
            <img src={greenDot} />
          </span>
          <p className="text-[#475467] text-sm">Attention</p>
        </div>
      </div>
      <Chart type="bar" data={chartData} options={chartOptions} />
    </div>
  );
}

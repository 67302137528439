//assets
import linkOrange from 'assets/svg/link-orange.svg';
import lockCircle from 'assets/svg/lock-circle.svg';
import send from 'assets/svg/send-2.svg';

export default function ShareBriefcase() {
  return (
    <section className='p-5'>
      <div className='w-full'>
        <h1 className='text-xl font-medium'>Present</h1>
      </div>
      {/* Indicators */}
      <section className='flex items-center mt-3 px-4 w-full pt-3 pb-10 border-b border-t '>
        <div className='min-w-10 min-h-10 flex items-center justify-center border border-[#EAECF0] rounded-lg shadow-sm relative'>
          <img src={linkOrange} alt='link' />
          <div className='absolute -bottom-8 left-[50%] -translate-x-[50%] w-[70px]'>
            <p className='text-sm font-semibold'>Create link</p>
          </div>
        </div>
        <hr className='w-full' />
        <div className='min-w-10 min-h-10 flex items-center justify-center border border-[#EAECF0] rounded-lg shadow-sm relative'>
          <img src={lockCircle} alt='link' />
          <div className='absolute -bottom-8 left-[50%] -translate-x-[50%] w-[130px]'>
            <p className='text-sm text-[#333333]'>Manage permissions</p>
          </div>
        </div>
        <hr className='w-full' />
        <div className='min-w-10 min-h-10 flex items-center justify-center border border-[#EAECF0] rounded-lg shadow-sm relative'>
          <img src={send} alt='link' />
          <div className='absolute -bottom-8 left-[50%] -translate-x-[50%] w-[95px]'>
            <p className='text-sm text-[#333333]'>Share your link</p>
          </div>
        </div>
      </section>
    </section>
  );
}

import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

//components
import Loader from 'components/UI/Loader';
import CreateBriefcase from './Modals/CreateBriefcase';
// import BriefcaseList from './Tables/BriefcaseList';
// import AdminNavbar from 'components/UI/AdminLayout/Navbar';
// import FlexContainer from 'components/UI/StyleComponents/FlexContainer';
import AllBriefCase from '../../../components/Admin/BriefCase/AllBriefCase';

//assets
import addPlus from 'assets/svg/add-circle.svg';
import emptyState from 'assets/svg/emptyBriefcase.svg';
import bell from 'assets/svg/bell.svg';
// import upload from 'assets/svg/upload.svg';
import menu from 'assets/svg/hamburger.svg';

//libraries
import { Modal } from 'antd';

//slices
import {
  getBriefcases,
  getBriefcaseDocuments,
  // getBriefcaseState,
} from 'store/Briefcase/briefcaseSlice';
import { getTeamInfo, getTeamsState } from 'store/Teams/teamsSlice';
// import { setTab } from 'store/Briefcase/briefcaseTabSlice';
// import AdminNav from 'components/Admin/BriefCase/AdminNav';
import { AppDispatch, RootState } from 'store/store';
// import { Outlet } from 'react-router-dom';

export default function MyBriefCase() {
  // const dispatch = useDispatch();
  interface CreateSpaceModalRef {
    resetModal: () => void;
  }
  //useRef
  const createSpaceModal = useRef<CreateSpaceModalRef>(null);

  //useselector
  const { teamInfo } = useSelector(getTeamsState);
  // const { briefcases } = useSelector(getBriefcaseState);
  const briefcases = useSelector(
    (state: RootState) => state.briefcase.briefcases
  );
  const dispatch = useDispatch<AppDispatch>();

  //usestates
  // const [navProps, setNavProps] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [tab, setTab] = useState(0);

  // gets the tab from store
  const tab = useSelector((state: RootState) => state.briefcaseTab.tab);

  //useeffects
  useEffect(() => {
    dispatch(getTeamInfo());
  }, [dispatch]);

  useEffect(() => {
    const teamId = teamInfo.data?.teamId;
    teamId && dispatch(getBriefcases(teamId));
    // teamId && dispatch(getBriefcaseDocuments("453ccfb6-2324-4cf8-a173-bb1a35e35eab"));
  }, [teamInfo, dispatch]);

  // useEffect(() => {
  //   setNavProps({
  //     title: 'Briefcase',
  //     subTitle: `Explore your presentations`,
  //     modalTitle: 'Create new space',
  //     func: () => window.dispatchEvent(new CustomEvent('openCreateSpaceModal')),
  //   });
  // }, []);

  //useeffects
  useEffect(() => {
    const handleEditEvent = () => {
      openCreateSpaceModal();
    };

    window.addEventListener('openCreateSpaceModal', handleEditEvent);

    return () => {
      window.removeEventListener('openCreateSpaceModal', handleEditEvent);
    };
  }, []);

  //function
  const openCreateSpaceModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    createSpaceModal.current?.resetModal();
  };

  return (
    <section className='relative'>
      {/* loader */}
      {(briefcases.isLoading || teamInfo.isLoading) && <Loader />}
      {!briefcases.isLoading && !teamInfo.isLoading && tab === 0 && (
        <>
          {/* Nav */}
          <nav className='fixed top-0  lg:left-[18%] left-0 z-30 lg:w-[82%] w-full h-20 bg-white pt-4'>
            <section className='w-[95%] mx-auto'>
              <div className='flex items-center justify-between flex-col lg:flex-row gap-y-4'>
                <section className='flex items-center justify-between w-full lg:w-[30%]'>
                  <img
                    src={menu}
                    alt='open sidenav'
                    className='block lg:hidden'
                    // onClick={handleClick}
                  />
                  <div className='leading-tight text-right lg:text-left justify-items-end justify-self-end lg:self-start'>
                    <h1 className='text-lg font-bold lg:text-2xl font-grotesk'>
                      Briefcase
                    </h1>
                    <p className='lg:text-sm text-xs mt-[-3px]'>
                      Create a briefcase to manage your events and files
                    </p>
                    {/* {breadcrumb} */}
                  </div>
                </section>

                <div className='items-center justify-between flex-col lg:flex-row gap-y-4 gap-x-2 lg:flex hidden'>
                  {/* {modalTitle && (
                <FlexContainer
                  extraClass='bg-primary cursor-pointer rounded-lg  border-primary text-white text-sm px-2 py-2 gap-x-1 text-xs'
                  onClick={navProps.func}
                >
                  <img src={upload} alt='upload slide' className='w-4' />
                  <p>{modalTitle}</p>
                </FlexContainer>
              )} */}

                  <div className='px-2 py-2 border rounded-md cursor-pointer bg-gray20'>
                    <img src={bell} alt='notification' className='w-4' />
                  </div>
                </div>
              </div>
            </section>
          </nav>

          <section className='mt-20'>
            {/*  */}

            {!briefcases.isLoading && (
              <section>
                {(briefcases.data?.length < 1 ||
                  (!teamInfo.isLoading && !teamInfo.data)) && (
                  <section className='flex flex-col  items-center justify-center lg:h-[60vh]'>
                    <div className='mb-4 center-content'>
                      <img
                        src={emptyState}
                        alt='No briefcase'
                        className='center-content'
                      />
                    </div>

                    <div className='text-center'>
                      <h1 className='text-xl font-semibold lg:text-2xl'>
                        A briefcase for all your playlists
                      </h1>
                      <p className='text-sm'>
                        Create and manage your presentation playlists here.
                      </p>
                      <div className='mt-2 center-content'>
                        <button
                          onClick={openCreateSpaceModal}
                          // onClick={() => dispatch(setTab(1))}
                          className='flex items-center px-4 py-2 text-sm text-white bg-black rounded-lg gap-x-2 '
                        >
                          <img src={addPlus} alt='' className='w-5 h-5' />
                          <span>Create a briefcase</span>
                        </button>
                      </div>
                    </div>

                    <section></section>
                  </section>
                )}

                {briefcases.data?.length > 0 && (
                  <section>
                    <AllBriefCase />
                  </section>
                )}
              </section>
            )}
          </section>

          {/* Modal */}
          <Modal
            footer={null}
            centered={false}
            open={isModalOpen}
            onCancel={handleCancel}
            style={{
              top: 20,
            }}
          >
            <CreateBriefcase closeModal={handleCancel} ref={createSpaceModal} />
          </Modal>
        </>
      )}
    </section>
  );
}

import { useRef, useState } from 'react';

//assets
import { Modal } from 'antd';
import bell from 'assets/svg/bell.svg';
import menu from 'assets/svg/hamburger.svg';
import addCircle from 'assets/svg/add-circle.svg';

// Components
import CreateSpace from 'views/Admin/Briefcase/Modals/CreateBriefcase';

export default function AdminNav() {
  interface CreateSpaceModalRef {
    resetModal: () => void;
  }
  const createSpaceModal = useRef<CreateSpaceModalRef>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  //function
  const openCreateSpaceModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    createSpaceModal.current?.resetModal();
  };

  return (
    <section className='relative'>
      {/* Nav */}
      <nav className='fixed top-0  lg:left-[18%] left-0 z-30 lg:w-[82%] w-full h-20 bg-white pt-4'>
        <section className='w-[95%] mx-auto'>
          <div className='flex items-center justify-between  flex-col lg:flex-row gap-y-4'>
            <section className='flex items-center justify-between w-full lg:w-[30%]'>
              <img
                src={menu}
                alt='open sidenav'
                className='block lg:hidden'
                // onClick={handleClick}
              />
              <div className='leading-tight text-right lg:text-left justify-items-end justify-self-end lg:self-start'>
                <h1 className='text-lg font-bold lg:text-2xl font-grotesk'>
                  Briefcase
                </h1>
                <p className='lg:text-sm text-xs mt-[-3px]'>
                  Create a briefcase to manage your events and files
                </p>
                {/* {breadcrumb} */}
              </div>
            </section>

            <section className='flex gap-x-2'>
              <div
                className='bg-[#FF6929] px-2 py-2 text-white rounded-md text-sm flex gap-x-2 items-center cursor-pointer'
                onClick={() => {
                  openCreateSpaceModal();
                }}
              >
                <img src={addCircle} alt='add new briefcase' />
                <p>Create new briefcase</p>
              </div>
              <div className='flex items-center justify-between  flex-col lg:flex-row gap-y-4'>
                <div className='px-2 py-2 border rounded-md cursor-pointer bg-gray20'>
                  <img src={bell} alt='notification' className='w-4' />
                </div>
              </div>
            </section>
          </div>
        </section>
      </nav>

      {/* Modal */}
      <Modal
        footer={null}
        centered={false}
        open={isModalOpen}
        onCancel={handleCancel}
        style={{
          top: 20,
        }}
      >
        <CreateSpace closeModal={handleCancel} ref={createSpaceModal} />
      </Modal>
    </section>
  );
}

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//assets
import dots from "assets/svg/dots.svg";
import sort from "assets/svg/sort.svg";
import move from "assets/svg/move.svg";
import link from "assets/svg/link.svg";
import trash from "assets/svg/trash.svg";
import access from "assets/svg/access.svg";
import search from "assets/svg/search.svg";
import rename from "assets/svg/rename.svg";
// import avatar from "assets/svg/avatar.svg";
import presentNow from "assets/svg/presentNow.svg";

//components
import EmptyState from "./EmptyState";
import UpgradeTeam from "./UpgradeTeam";
import Loader from "components/UI/Loader";
import FileThumbnail from "./FileThumbnail";
import ConfirmationModal from "components/UI/Modal/ConfirmationModal";
// import FlexContainer from "components/UI/StyleComponents/FlexContainer";
import SetPresentationTime from "views/Admin/Presentation/Modals/SetTime";

//libraries
import moment from "moment";
import { toast } from "react-toastify";
import { Column } from "primereact/column";
import { Dropdown, Menu, Modal } from "antd";
import { DataTable } from "primereact/datatable";
import { FilterMatchMode } from "primereact/api";

//slices
import { deleteFolder, getFolderFiles } from "store/Workspace/workspaceSlice";
import { deleteDocument } from "store/Presentations/presentationSlice";
import {
  getFileStructureState,
  pushOpenedFolder,
  setMostActiveFolder,
} from "store/FileStructure/fileStructureSlice";
import { useLocation } from "react-router-dom";
import UpgradeAccount from "components/Admin/Team/UpgradeAccount";
import { DateFromNow } from "utils/formatDate";

export default function ContentPage({ setUploadSelectedFile }) {
  const dispatch = useDispatch();

  const { pathname } = useLocation();

  //useref
  const setPresentationTimeRef = useRef();
  const selectedFile = useRef(null);

  //useselector
  //highlight
  // const { openedFolders } = useSelector(getFileStructureState);
  const { mostActiveFolder } = useSelector(getFileStructureState);
  const data = useSelector(
    (state) =>
      state.workspace.folderFiles.data[mostActiveFolder?.id]?.files ??
      state.workspace.workspaceFolders.tableData?.[mostActiveFolder?.id]?.files
  );

  const isLoading = useSelector(
    (state) => state.workspace.folderFiles?.isLoading
  );

  //usestates
  const [isUpgraded] = useState(true);
  const [setIsFileUploadModalOpen] = useState(false);
  const [documentList, setDocumentList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState([]);
  const [presentationDoc, setPresentationDoc] = useState(null);
  const [selectedDocumentId, setSelectedDocumentId] = useState(null);

  //usestate
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [confirmFolderDelete, setConfirmFolderDelete] = useState(false);
  const [isFolderDeleteLoading, setIsFolderDeleteLoading] = useState(false);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  //useffect
  useEffect(() => {
    const { subFolders, documents } = data ?? {};
    if (subFolders || documents) {
      const folders = data?.subFolders?.map((folder) => {
        const { Id, Title, DateModified, access = null } = folder ?? {};
        return {
          id: Id,
          title: Title,
          lastUpdated: DateModified,
          access,
          isFolder: true,
        };
      });

      const documents = data?.documents?.map((doc) => {
        const {
          id,
          title,
          dateModified,
          access = null,
          folderId,
          url,
        } = doc ?? {};
        return {
          id,
          url,
          title,
          access,
          isFolder: false,
          folderId: folderId,
          lastUpdated: dateModified,
        };
      });

      setDocumentList([...folders, ...documents]);
    } else if (data?.length > 0) {
      const list = data?.map((doc) => {
        const { id, title, dateModified, access = null } = doc ?? {};
        return {
          id,
          title,
          access,
          isFolder: true,
          lastUpdated: dateModified,
        };
      });
      setDocumentList(list);
    }
  }, [data]);

  const items = [
    {
      key: "1",
      label: (
        <div
          className="flex gap-x-2"
          onClick={() => {
            setPresentationDoc(selectedFile.current?.id);
            setIsModalOpen(true);
          }}
        >
          <img src={presentNow} alt="create new folder" className="w-4" />
          <p>Prepare Presentation</p>
        </div>
      ),
      // onClick: (file) => {
      //   setPresentationDoc(file?.id);
      //   setIsModalOpen(true);
      // },
    },

    // {
    //   key: '2',
    //   label: (
    //     <div
    //       className='flex gap-x-2'
    //       onClick={() => setIsFileUploadModalOpen(true)}
    //     >
    //       <img src={move} alt='move file' className='w-4' />
    //       <p>Move file</p>
    //     </div>
    //   ),
    // },

    // {
    //   key: '3',
    //   label: (
    //     <div
    //       className='flex gap-x-2'
    //       onClick={() => setIsFileUploadModalOpen(true)}
    //     >
    //       <img src={rename} alt='rename file' className='w-4' />
    //       <p>Rename file</p>
    //     </div>
    //   ),
    // },

    // {
    //   key: '4',
    //   label: (
    //     <div
    //       className='flex gap-x-2'
    //       onClick={() => setIsFileUploadModalOpen(true)}
    //     >
    //       <img src={access} alt='access control' className='w-4' />
    //       <p>Access control</p>
    //     </div>
    //   ),
    // },

    // {
    //   key: '5',
    //   label: (
    //     <div
    //       className='flex gap-x-2'
    //       onClick={() => setIsFileUploadModalOpen(true)}
    //     >
    //       <img src={link} alt='share file' className='w-4' />
    //       <p>Share</p>
    //     </div>
    //   ),
    // },

    {
      key: "6",
      label: (
        <div
          className="flex gap-x-2"
          onClick={() =>
            selectedDocumentId?.isFolder
              ? setConfirmFolderDelete(true)
              : setConfirmDelete(true)
          }
        >
          <img src={trash} alt="delete file" className="w-4" />
          <p>Delete</p>
        </div>
      ),
    },
  ];

  const columns = [
    {
      sortable: true,
      field: "title",
      header: "File name",
      body: (file) => <FileThumbnail file={file} />,
    },
    {
      field: "dateAdded",
      sortable: true,
      header: "Last updated",
      body: (rowData) => {
        return (
          <div className="">
            <p>{DateFromNow(rowData?.lastUpdated ?? new Date())}</p>
          </div>
        );
      },
    },
    // {
    //   header: "Access",
    //   body: (file) => {
    //     return (
    //       <div className="flex items-center gap-x-4">
    //         {file?.access?.map((avi, index) => {
    //           // Calculate position offsets
    //           let marginLeft;
    //           if (index === 0) {
    //             marginLeft = "ml-[0px]";
    //           } else {
    //             marginLeft = "ml-[-20px]";
    //           }
    //           return (
    //             <img
    //               key={index}
    //               src={avi}
    //               alt={file?.name}
    //               className={`object-contain h-8 w-8 rounded-[50%] ${marginLeft}`}
    //             />
    //           );
    //         })}
    //       </div>
    //     );
    //   },
    // },
    {
      field: "",
      header: "",
      body: (file) => {
        return (
          <section
            onClick={(e) => {
              e.stopPropagation();
              selectedFile.current = file;
            }}
          >
            <Dropdown
              trigger={["click"]}
              dropdownRender={(menu) => (
                <Menu>
                  {items.map((item, index) => (
                    <Menu.Item
                      key={item.key}
                      onClick={() => {
                        setSelectedDocumentId(file);
                        // item?.onClick(file);
                      }}
                    >
                      {file.isFolder && item.key == 1 ? "" : item.label}
                    </Menu.Item>
                  ))}
                </Menu>
              )}
            >
              <div className="cursor-pointer">
                <img src={dots} alt="options" className="w-7" />
              </div>
            </Dropdown>
          </section>
        );
      },
    },
  ];

  const handleCancel = () => {
    setIsModalOpen(false);
    setPresentationTimeRef.current.resetModal();
  };
  const closeConfirmDelete = () => {
    setConfirmDelete(false);
  };
  const closeFolderConfirmDelete = () => {
    setConfirmFolderDelete(false);
  };

  const deleteWorkspaceFolder = () => {
    setIsFolderDeleteLoading(true);
    dispatch(deleteFolder(selectedDocumentId?.id)).then(({ type }) => {
      setIsFolderDeleteLoading(false);

      if (type.includes("fulfilled")) {
        closeFolderConfirmDelete();
        toast.success("Folder deleted successfully");
        window.location.reload();
        // to be looked at
        dispatch(getFolderFiles(mostActiveFolder?.id));
      }
    });
  };

  const trashPresentation = () => {
    setIsDeleteLoading(true);
    let isArchive = false;
    const selectedDocument = selectedDocumentId?.id;

    dispatch(deleteDocument({ selectedDocument, isArchive })).then(
      ({ type }) => {
        setIsDeleteLoading(false);

        if (type.includes("fulfilled")) {
          closeConfirmDelete();
          toast.success("Document deleted successfully");

          dispatch(getFolderFiles(mostActiveFolder?.id));
        }
      }
    );
  };

  const openFolder = ({ data }) => {
    const { id, isFolder, title } = data ?? {};
    //highlight

    isFolder && dispatch(pushOpenedFolder({ id, title }));

    pathname?.includes("presentation-details") && setUploadSelectedFile(data);

    if (isFolder) {
      dispatch(setMostActiveFolder(data));
      dispatch(getFolderFiles(id));
    }
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;

    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  return (
    <section>
      {/* search & filter */}
      <section>
        <section className={`w-full justify-end flex`}>
          {/* <div className="flex items-center px-4 py-2 text-xs rounded-md gap-x-2 bg-gray30">
            <img src={sort} alt="sort" />
            All files
          </div> */}

          {mostActiveFolder !== "team" && (
            <div className="flex items-center self-end justify-end w-56 px-4 py-2 text-xs rounded-md gap-x-2 bg-gray30">
              <img src={search} alt="sort" />
              <input
                placeholder=" search folders"
                value={globalFilterValue}
                onChange={onGlobalFilterChange}
                className="bg-transparent w-[90%] py-1 outline-none"
              />
            </div>
          )}
        </section>
      </section>
      {/*  */}

      {/* content */}
      {mostActiveFolder === "team" && <UpgradeAccount />}

      {mostActiveFolder !== "team" && (
        <section>
          {isLoading && <Loader />}

          {!isLoading && (
            <section>
              {documentList?.length > 0 && (
                <div className="my-4">
                  <DataTable
                    value={documentList}
                    paginator
                    rows={30}
                    className="text-xs"
                    // selectionMode="checkbox"
                    // selection={selectedStaff}
                    tableStyle={{ minWidth: "50rem" }}
                    onRowClick={openFolder}
                    // onSelectionChange={(e) => setSelectedStaff(e.value)}
                    filters={filters}
                    globalFilterFields={["title"]}
                  >
                    <Column
                      // selectionMode='multiple'
                      headerStyle={{ width: "3rem" }}
                    ></Column>
                    {columns?.map((col, index) => {
                      if (
                        pathname
                          ?.toLowerCase()
                          ?.includes("/presentation-details") &&
                        col.field === "options"
                      ) {
                        // eslint-disable-next-line array-callback-return
                        return;
                      }

                      return (
                        <Column
                          key={index}
                          body={col?.body}
                          field={col.field}
                          header={col.header}
                          sortable={col?.sortable}
                          className="cursor-pointer"
                        />
                      );
                    })}
                  </DataTable>
                </div>
              )}

              {documentList?.length < 1 && (
                <div className="h-[70vh] flex items-center">
                  <EmptyState type={"file"} />
                </div>
              )}
            </section>
          )}
        </section>
      )}

      {/*  */}

      <ConfirmationModal
        open={confirmDelete}
        isLoading={isDeleteLoading}
        onClose={closeConfirmDelete}
        func={trashPresentation}
        text="Are you sure you want to delete this document?"
      />

      <ConfirmationModal
        open={confirmFolderDelete}
        isLoading={isFolderDeleteLoading}
        onClose={closeFolderConfirmDelete}
        func={deleteWorkspaceFolder}
        text="Are you sure you want to delete this folder?"
      />

      <Modal
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        // maskClosable={false}
      >
        <SetPresentationTime
          docId={presentationDoc}
          closeModal={handleCancel}
          ref={setPresentationTimeRef}
        />
      </Modal>
    </section>
  );
}

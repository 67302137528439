import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//assets
import logo from "assets/svg/logo2.svg";
import people from "assets/svg/peopleWaiting.svg";
import hourGlass from "assets/image/hourGlass.png";

//slices
import {
  connectSignalR,
  getConnectionState,
  getSignalRConnection,
  getSignalRState,
} from "store/SignalR/signalRSlice";
import JoinForm from "components/User/JoinPresentation/JoinForm";
import JoinOTP from "components/User/JoinPresentation/OTP";
import JoinPresentationLoader from "components/UI/Loader/JoinPresentationLoader";

//utils
import { glassStyle, style } from "./variables";
import {
  getConnectionId,
  getToken,
  getUserDetails,
  localStorageGetter,
  resetTimer,
  resetUnlockedSlides,
} from "utils/localStorageServices";

//hooks
import { useHasPresentationStarted } from "hooks/SignalR/Listeners/Viewers/useHasPresentationStarted";
import { joinPresentationUseCase } from "hooks/SignalR/EventResponse/UseCases/JoinPresentationUseCase";
import { useJoinPresentationEventResponse } from "hooks/SignalR/EventResponse/useJoinPresentationResponse";
import { useIsPresentationAccessGiven } from "hooks/SignalR/Listeners/Viewers/useIsPresentationAccessGiven";
import { RequestPresentationAccess } from "hooks/SignalR/Invokers/Viewer/RequestPresentationAccess";
import { useSignalREventListener } from "hooks/SignalR/Listeners/Global/useSignalRListener";

import { toast } from "sonner";

export default function JoinPresentation() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { presentationId } = useParams();

  const { isConnected } = useSelector(getSignalRState);
  let connection = getSignalRConnection();

  const connectionState = getConnectionState();

  //hooks
  useJoinPresentationEventResponse(
    connection,
    (id, eventName, status, message, presenterConnectionId) => {
      joinPresentationUseCase(
        id,
        eventName,
        status,
        message,
        presenterConnectionId,
        navigate,
        setIsLoading,
        setNextStage,
        setReference,
        setLoadPage
      );
    }
  );

  useHasPresentationStarted(connection, presentationId);
  useIsPresentationAccessGiven(connection, presentationId);

  useSignalREventListener(
    connection,
    (id, eventName, status, message, presenterConnectionId) => {
      const event = eventName?.toLowerCase();
      if (!status) {
        toast.error(message?.message ?? "No data found");
      }
      console.log(event, message, status, "event name");
    }
  );

  //usestate
  const [nextStage, setNextStage] = useState(false);
  const [reference, setReference] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadPage, setLoadPage] = useState(false);

  const userDetails = getUserDetails();

  useEffect(() => {
    setLoadPage(false);
    const token = getToken();
    const userInfo = getUserDetails();
    const { email, fullName } = userInfo ?? {};
    const gottenOTP = localStorageGetter("gottenOTP");
    if (gottenOTP) {
      setLoadPage(true);
      return;
    }

    if (
      connectionState === "Connected" &&
      token &&
      userInfo &&
      !getConnectionId()
    ) {
      RequestPresentationAccess(connection, presentationId, email, fullName);
    } else {
      setLoadPage(true);
    }
  }, [connection, presentationId, connectionState]);

  useEffect(() => {
    resetTimer();
    resetUnlockedSlides();
  }, []);

  useEffect(() => {
    localStorage.setItem("connectionId", "");
    if (!connection) {
      dispatch(connectSignalR());
    }
  }, [dispatch, connection]);

  //funtions
  const triggerLoading = () => {
    setIsLoading(true);
  };

  return (
    <section
      className="h-screen w-full [ flex justify-center items-center ]"
      style={style}
    >
      <section
        style={glassStyle}
        className=" rounded-lg
        [ lg:w-[80%] w-[95%]  ]  
          [ shadow-black/70 shadow-2xl ] [ mx-auto lg:p-10 px-2 py-4  ] [ flex justify-between items-center ] "
      >
        {(connectionState !== "Connected" || !loadPage) && (
          <JoinPresentationLoader />
        )}

        {connectionState === "Connected" && loadPage && (
          <section className=" [ flex justify-between items-center lg:flex-row flex-col-reverse gap-y-4 ] w-full">
            {nextStage !== "waiting" && (
              <section className="lg:w-[43%] w-full rounded-lg [ backdrop-blur-lg bg-gradient-to-b from-[#17191C52]/60 to-[#17191C52]/30 ]  px-4 py-6">
                <div className="[ flex justify-center ]">
                  <img src={logo} alt="" className="w-16" />
                </div>

                {!nextStage ? (
                  <JoinForm
                    setNextStage={setNextStage}
                    connection={connection}
                    presentationId={presentationId}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    triggerLoading={triggerLoading}
                  />
                ) : (
                  <JoinOTP
                    reference={reference}
                    connection={connection}
                    setNextStage={setNextStage}
                    presentationId={presentationId}
                    isLoading={isLoading}
                    triggerLoading={triggerLoading}
                  />
                )}
              </section>
            )}

            {nextStage === "waiting" && (
              <section className=" w-[40%] flex items-center justify-center">
                <img
                  src={hourGlass}
                  alt="waiting to be let in"
                  className="w-20 lg:w-32"
                />
              </section>
            )}

            <section className="flex flex-col items-center justify-center text-white gap-y-4">
              {nextStage === "waiting" ? (
                <div>
                  <h3 className="text-3xl font-semibold text-center text-white">
                    Waiting Room
                  </h3>
                  <p className="mt-3 text-center">
                    Hold on, the presenter has been notified you want to join{" "}
                  </p>
                </div>
              ) : (
                <div>
                  <h3 className="text-2xl font-semibold text-center text-white lg:text-3xl">
                    Welcome <br />{" "}
                    {userDetails?.fullName ?? "to the presentation"}
                  </h3>
                </div>
              )}

              <img src={people} alt="" className="hidden lg:block" />
            </section>
          </section>
        )}
      </section>
    </section>
  );
}

import React from "react";

import { useDispatch } from "react-redux";

//slices
import { openSidebar } from "store/UI";

//libraries
//assets
import bell from "assets/svg/bell.svg";
import upload from "assets/svg/upload.svg";
import menu from "assets/svg/hamburger.svg";

//components
import FlexContainer from "../StyleComponents/FlexContainer";

export default function AdminNavbar({ navProps }) {
  const dispatch = useDispatch();
  const { title, subTitle, modalTitle, breadcrumb } = navProps;

  //usestate

  //functions

  const handleClick = (e) => {
    e.stopPropagation();
    dispatch(openSidebar());
  };

  return (
    <section className="fixed top-0  lg:left-[18%] left-0 z-30 lg:w-[82%] w-full h-20 bg-white pt-4">
      <section className="w-[95%] mx-auto">
        <FlexContainer>
          <section className="flex items-center justify-between w-full lg:w-[30%]">
            <img
              src={menu}
              alt="open sidenav"
              className="block lg:hidden"
              onClick={handleClick}
            />
            <div className="leading-tight text-right lg:text-left justify-items-end justify-self-end lg:self-start">
              <h1 className="text-lg font-bold lg:text-2xl font-grotesk">
                {title}
              </h1>
              <p className="lg:text-sm text-xs mt-[-3px]">{subTitle}</p>
              {breadcrumb}
            </div>
          </section>

          <FlexContainer extraClass="gap-x-2 lg:flex hidden">
            {modalTitle && (
              <FlexContainer
                extraClass="bg-primary cursor-pointer rounded-lg  border-primary text-white text-sm px-2 py-2 gap-x-1 text-xs"
                onClick={navProps.func}
              >
                <img src={upload} alt="upload slide" className="w-4" />
                <p>{modalTitle}</p>
              </FlexContainer>
            )}

            <div className="px-2 py-2 border rounded-md cursor-pointer bg-gray20">
              <img src={bell} alt="notification" className="w-4" />
            </div>

            {/* <div
              onClick={logoutFunc}
              className="px-2 rounded-md cursor-pointer "
            >
              <i className="pi pi-sign-out"></i>
            </div> */}
          </FlexContainer>
        </FlexContainer>
      </section>
    </section>
  );
}

import React, {
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useDispatch, useSelector } from "react-redux";

//assets

import upload from "assets/svg/uploadDark.svg";
import uploadWhite from "assets/svg/upload.svg";

//slices
import { uploadPPT } from "store/Slides/slidesSlice";
import { getFolderFiles } from "store/Workspace/workspaceSlice";

//libraries
import { v4 as uuidv4 } from "uuid";
import { ClipLoader } from "react-spinners";

//components
import ImageUploadPreview from "./ImageUploadPreview";

//slices
import { getFileStructureState } from "store/FileStructure/fileStructureSlice";

//utils
import { getFileType } from "utils/getFileType";
import { isFileTypeAllowed } from "utils/formatter";
import { toast } from "sonner";

const UploadDocuments = (props, ref) => {
  const { closeModal } = props;

  const dispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    resetModal() {
      setMultiFiles([]);
    },
  }));

  //useref
  const uploadFileRef = useRef();

  //useselector
  const { mostActiveFolder } = useSelector(getFileStructureState);

  //usestate
  const [pptFile, setPptFile] = useState(null);
  const [multiFiles, setMultiFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [uploadProgress, setUploadProgress] = useState(0);
  const [isButtonActive, setIsButtonActive] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({});

  //functions
  const deleteUpload = (id) => {
    const filteredUpload = multiFiles.filter((upload) => {
      return upload?.id !== id;
    });
    setMultiFiles(filteredUpload);
  };

  const handleFileUpload = (e) => {
    const files = Array.from(e.target.files);

    // Clear the file input value to allow selecting the same file again
    if (e.target?.value) {
      e.target.value = null;
    }

    const newUploads = files.reduce((acc, file) => {
      const originalFileName = file?.name;
      const sizeInBytes = file?.size;

      // Check if the file type is allowed
      if (!isFileTypeAllowed(file)) {
        toast.success(`${originalFileName}: This file type is not allowed.`);

        return acc;
      }

      let displaySize;
      if (sizeInBytes >= 1024 * 1024) {
        const sizeInMB = (sizeInBytes / (1024 * 1024)).toFixed(2);
        displaySize = `${sizeInMB} MB`;
      } else {
        const sizeInKB = (sizeInBytes / 1024).toFixed(2);
        displaySize = `${sizeInKB} KB`;
      }

      const data = {
        file,
        id: uuidv4(),
        name: originalFileName,
        imgUrl: URL.createObjectURL(file),
        size: displaySize?.toLocaleString(),
        mime: getFileType(originalFileName),
      };

      return [...acc, data];
    }, []);

    setMultiFiles([...multiFiles, ...newUploads]);
  };

  const openUploadModal = () => {
    uploadFileRef.current.click();
  };

  const dragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "move";
  };

  const drop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setIsButtonActive(true);
    setPptFile(file);
  };

  const submitUpload = async () => {
    const folderId = mostActiveFolder?.id;

    if (multiFiles.length < 1) {
      toast.error("Upload a document");
      return;
    }

    setIsLoading(true);
    setIsButtonActive(false);

    // Initialize an object to track progress for each file
    const initialProgressState = multiFiles.reduce((acc, file) => {
      acc[file.id] = 0; // Start each file's progress at 0%
      return acc;
    }, {});

    setUploadProgress(initialProgressState);

    // Upload each file in parallel
    const uploadPromises = multiFiles.map((fileData) => {
      const formData = new FormData();
      formData.append("Title", fileData.name);
      formData.append("FolderId", folderId);
      formData.append("Files", fileData.file);
      formData.append("Note", "");

      const config = {
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          );

          // Update progress for the specific file
          setUploadProgress((prevProgress) => ({
            ...prevProgress,
            [fileData.id]: progress,
          }));
        },
      };

      return dispatch(uploadPPT({ data: formData, config })).then(
        ({ payload }) => {
          if (payload) {
            toast.success(`${fileData.name} uploaded successfully`);
          } else {
            toast.error(`${fileData.name} failed to upload`);
          }
        }
      );
    });

    // Wait for all uploads to complete
    await Promise.all(uploadPromises);

    setIsLoading(false);
    setIsButtonActive(true);
    setUploadComplete(true);
    setPptFile(null);
    closeModal();
    dispatch(getFolderFiles(folderId));
  };

  return (
    <section className="">
      <div className="mt-[-10px]">
        <img src={upload} alt="upload slide" />
      </div>

      <section className="w-[93%] mx-auto my-4 font-grotesk">
        <div>
          <h1 className="text-2xl font-bold">Multi file upload</h1>
          {/* <p className="text-sm">
            you can only upload a PowerPoint or pdf format
          </p> */}
        </div>

        <div
          draggable
          onDrop={(e) => drop(e)}
          onDragOver={(e) => dragOver(e)}
          className="my-6 border-2 border-dashed rounded-lg cursor-pointer bg-gray20"
          onClick={openUploadModal}
        >
          <div className="flex flex-col items-center py-4 text-center">
            <p>
              {" "}
              <span className="font-semibold text-primary">
                Click to upload
              </span>{" "}
              or drag and drop
            </p>
            <p>Powerpoint, JPG or PDF </p>
          </div>

          <input
            hidden
            multiple
            type="file"
            ref={uploadFileRef}
            onChange={handleFileUpload}
          />
        </div>

        {/* documents  */}
        {multiFiles?.length > 0 && (
          <section className="flex flex-col mb-4 gap-y-2">
            {multiFiles?.map((upload) => {
              const { id } = upload ?? {};

              return (
                <ImageUploadPreview
                  key={id}
                  details={upload}
                  multiFiles={multiFiles}
                  deleteUpload={deleteUpload}
                  uploadProgress={uploadProgress[id]} // Pass progress here
                  setMultiFiles={setMultiFiles}
                />
              );
            })}
          </section>
        )}

        {/*  */}
        <button
          disabled={multiFiles.length < 1 || isLoading}
          onClick={submitUpload}
          className={`flex items-center justify-center w-full py-3 text-sm text-white rounded-lg cursor-pointer bg-primary gap-x-2 ${
            multiFiles.length > 0 || !isLoading
              ? "opacity-100"
              : "opacity-50 cursor-not-allowed"
          }`}
        >
          {isLoading && <ClipLoader size={15} color="white" />}

          {!isLoading && (
            <>
              <img src={uploadWhite} alt="upload slide" className="w-4" />
              <p>Upload multifile</p>
            </>
          )}
        </button>
      </section>
    </section>
  );
};

export default forwardRef(UploadDocuments);

import { useState } from 'react';

// components

//assets
import bell from 'assets/svg/bell.svg';
import menu from 'assets/svg/hamburger.svg';
import ellipsisVertical from 'assets/svg/ellipsis-vertical.svg';
import eyeI from 'assets/svg/eye-2.svg';
import chevronDownWhite from 'assets/svg/chevron-down-white.svg';
// import dot from 'assets/svg/_Dot.svg';
import avatar1 from 'assets/image/avatarFB.png';
import avatar2 from 'assets/image/avatarOl.png';
import clock from 'assets/svg/clock-icon.svg';
import ChevronDownBlack from 'assets/svg/chevron-down-black.svg';
import lines from 'assets/image/briefcaselayerlines.png';
import exportIconBlack from 'assets/svg/export-icon-black.svg';
import play from 'assets/svg/play2.svg';
import calender from 'assets/svg/calendar-2.svg';
import filesBanner from 'assets/image/files-banner.png';

// Libraries
import { AvatarGroup } from 'primereact/avatargroup';
import { Avatar } from 'primereact/avatar';
import FileVisits from './Tables/FileVisits';
import FileAnalytics from './Tables/FileAnalytics';
import FileUsage from './Tables/FileUsage';
import { Dropdown, Menu } from 'antd';

export default function BriefcaseFileDetails() {
  // States
  const [tab, setTab] = useState(0);

  // const items = [
  //   {
  //     name: "Marketing's deck",
  //     numberOfFiles: 120,
  //     lastUpdated: '2hr ago',
  //     tags: ['Design', 'Marketing', 'Finance', 'SEO'],
  //     access: [avatar1, avatar2, avatar2],
  //   },
  // ];

  //variables
  const items_ = [
    {
      key: '1',
      label: (
        <div
          className='flex gap-x-2'
          // onClick={() => setEditPresentationModal(true)}
        >
          <img src={play} alt='rename file' className='w-4' />
          <p>Create presentation</p>
        </div>
      ),
    },

    {
      key: '2',
      label: (
        <div
          className='flex gap-x-2'
          // onClick={() => navigate(`/admin/presentation-metric/${id}`)}
        >
          <img src={calender} alt='metrics file' className='w-4' />
          <p>Share file</p>
        </div>
      ),
    },
  ];

  return (
    <section>
      {/* Nav */}
      <nav className='fixed top-0  lg:left-[18%] left-0 z-30 lg:w-[82%] w-full h-20 bg-white pt-4'>
        <section className='w-[95%] mx-auto'>
          <section className='flex-col lg:flex-row gap-y-4 flex items-center justify-between'>
            <section className='flex items-center justify-between w-full lg:w-[30%]'>
              <img src={menu} alt='open sidenav' className='block lg:hidden' />
              <div className='leading-tight text-right lg:text-left justify-items-end justify-self-end lg:self-start'>
                <h1 className='text-lg font-bold lg:text-2xl font-grotesk'>
                  Briefcase
                </h1>
                <p className='lg:text-sm text-xs mt-[-3px]'>
                  Create a briefcase to manage your events and files
                </p>
              </div>
            </section>

            <section className='gap-x-2 lg:flex hidden items-center justify-between'>
              {/* btn */}
              <div className='h-8 rounded-md w-8 border border-[#E2E2E2] cursor-pointer flex items-center justify-center'>
                <img src={ellipsisVertical} alt='ellipsis icon' />
              </div>
              <div className='h-8 flex items-center gap-x-2 px-5 rounded-md border border-[#AFAFAF] text-sm text-[#545454]'>
                <p>Preview</p>
                <img src={eyeI} alt='eye' className='w-4' />
              </div>
              <section onClick={(e) => e.preventDefault()}>
                <Dropdown
                  trigger={['click']}
                  dropdownRender={(menu) => (
                    <Menu>
                      {/* onClick={() => item?.onClick(file)} */}
                      {items_.map((item) => {
                        // if ((status === 0 || status === 1) && item.key === '2') {
                        //   return;
                        // }
                        return (
                          <Menu.Item key={item.key}>{item.label}</Menu.Item>
                        );
                      })}
                    </Menu>
                  )}
                >
                  <div className='h-8 bg-black text-white flex items-center gap-x-2 px-5 rounded-md text-sm'>
                    <p>Create link</p>
                    <img src={chevronDownWhite} alt='eye' className='' />
                  </div>
                </Dropdown>
              </section>

              <div className='px-2 py-2 border rounded-md cursor-pointer bg-gray20'>
                <img src={bell} alt='notification' className='w-4' />
              </div>
            </section>
          </section>
        </section>
      </nav>
      {/* File Details Banner */}
      <section className='mt-20'>
        <div className='bg-[#F6F3EF] w-full h-full rounded-xl p-5 border border-[#F6F3EF] relative'>
          <div className='w-full flex items-center gap-x-4'>
            <img src={filesBanner} alt='file' />
            <div>
              <h1 className='text-lg font-bold lg:text-2xl font-grotesk'>
                Marketing deck
              </h1>
              <section className='flex gap-x-5 items-center text-sm'>
                <p>
                  Description goes here if theres any at all Description goes
                  here if theres any at all
                </p>
                <div className='flex items-center gap-x-2'>
                  <img src={clock} alt='clock' />
                  <p>Last updated 5 min ago</p>
                </div>
              </section>
              <div className='mt-3'>
                <AvatarGroup>
                  <Avatar image={avatar1} size='normal' shape='circle' />
                  <Avatar image={avatar2} size='normal' shape='circle' />
                  <Avatar image={avatar1} size='normal' shape='circle' />
                  <Avatar label='+2' shape='circle' size='normal' />
                </AvatarGroup>
              </div>
            </div>
          </div>
          <img
            src={lines}
            alt='briefcase'
            className=' absolute right-0 top-0'
          />
        </div>
      </section>

      {/* tabs */}
      <section className='mt-5 mb-20'>
        <section className='flex gap-x-4 items-center justify-between'>
          <section className='flex gap-x-2 border-b'>
            {/* <Link to='/admin/mybriefcase/details?tab=all-files'> */}
            <button
              onClick={() => setTab(0)}
              className={`px-4 pb-2 cursor-pointer text-sm  ${
                tab === 0 && 'border-b-2  text-primary border-b-primary'
              }`}
            >
              Visits
            </button>
            {/* </Link> */}

            {/* <Link to='/admin/mybriefcase/details?tab=share-history'> */}
            <button
              onClick={() => setTab(1)}
              className={`px-4 pb-2 cursor-pointer text-sm  ${
                tab === 1 && 'border-b-2  text-primary border-b-primary'
              }`}
            >
              Analytics
            </button>
            {/* </Link> */}

            {/* <Link to='/admin/mybriefcase/details?tab=presentation-history'> */}
            <button
              onClick={() => setTab(2)}
              className={`px-4 pb-2 cursor-pointer text-sm  ${
                tab === 2 && 'border-b-2  text-primary border-b-primary'
              }`}
            >
              Usage
            </button>
            {/* </Link> */}
          </section>
          <section className='flex items-center gap-x-2'>
            <div className='bg-[#F6F6F6] text-[#333333] text-sm h-8 rounded-md flex gap-x-1 items-center cursor-pointer border border-[#E2E2E2] px-3'>
              <img src={exportIconBlack} alt='export icon' />
              <p>Export</p>
              <img src={ChevronDownBlack} alt='more' />
            </div>
          </section>
        </section>

        <section className='mt-1 mb-8 pb-20'>
          {tab === 0 && <FileVisits />}
          {tab === 1 && <FileAnalytics />}
          {tab === 2 && <FileUsage />}
        </section>
      </section>
    </section>
  );
}

import pdfIcon from "assets/svg/pdf.svg";
import powerPointIcon from "assets/svg/powerPointIcon.svg"
import jpgIcon from "assets/svg/jpgIcon.svg"
import jpegIcon from "assets/svg/jpeg.svg"
import pngIcon from "assets/svg/pngIcon.svg"
import fileSvg from "assets/svg/fileSvg.svg"

export const resolveFileType = (text: string) => {
    const fileExtension = text.split('.')[text.split('.').length - 1].toLowerCase()
    if (fileExtension == 'pdf') {
        return pdfIcon
    }
    else if (fileExtension == "pptx") {
        return powerPointIcon
    }
    else if (fileExtension == 'jpg') {
        return jpgIcon
    }
    else if (fileExtension == 'jpeg') {
        return jpegIcon
    }
    else if (fileExtension == 'png') {
        return pngIcon
    }

    return fileSvg
}


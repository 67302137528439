import React from "react";
import { Chart } from "primereact/chart";
import "primereact/resources/themes/saga-blue/theme.css"; // Optional, for a PrimeReact theme
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

export const LineChart = ({
  labels,
  data,
}: {
  labels: string[];
  data: number[];
}) => {
  // Line Chart Data
  const lineData = {
    labels: labels,
    datasets: [
      {
        data: data,
        fill: false, // Prevent the area under the line from being filled
        borderColor: "#E86025", // Line color
        tension: 0.4, // Makes the line a bit smoother, you can adjust this for more or less curve
      },
    ],
  };

  const lineOptions = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio:1,
    onClick: (event: any, chartElement: any) => {
      console.log(chartElement, "Chart Element Clicked");
      console.log("this point got clicked");
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        display: true,
        offset: true,
        title: {
          display: false,
          text: "Months",
        },
        grid: {
          display: false,
        },
        min: 0,
      },
      y: {
        display: true,
        suggestedMin: 0,
        min: 0,
        title: {
          display: false,
          text: "Documents",
        },
      },
    },
  };

  // Line chart Data
  return (
    <div>
      <Chart type="line" data={lineData} options={lineOptions} />
    </div>
  );
};

import React, { useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

//libraries

//assets
import empty from "assets/svg/emptyPresentation.svg";
import notFound from "assets/svg/presentation-notfound.svg";

//utils
import { navProps } from "./data";

//slices
import {
  getAllPresentations,
  getPresentationState,
} from "store/Presentations/presentationSlice";

//components
import Loader from "components/UI/Loader";
import AdminNavbar from "components/UI/AdminLayout/Navbar";
// import EmptyState from "components/Admin/Workspace/ContentPage/EmptyState";
import NavigationTab from "components/Admin/Presentation/PresentationDetails/NavigationTab";
import PresentationCard from "components/Admin/Presentation/Cards/PresentationCard";

export default function Presentation() {
  const dispatch = useDispatch();
  const { presentations } = useSelector(getPresentationState);

  //useref
  const searchParam = useRef();

  //usestates
  const [tab, setTab] = useState(1);
  const [allPresentations, setAllPresentations] = useState();
  const [grouppedPresentations, setGrouppedPresentations] = useState();

  //useeffect
  useEffect(() => {
    dispatch(getAllPresentations());
  }, [dispatch, tab]);

  useEffect(() => {
    // const sortedPresentation =  presentations?.data?.sort((a,b)=>{
    //   if(a?.)
    // })
    setGrouppedPresentations(
      presentations?.data?.filter((presentation) => {
        if (tab === 2) {
          return presentation?.status === tab || presentation?.status === 3;
        }
        return presentation?.status === tab;
      })
    );

    setAllPresentations(presentations?.data);
  }, [presentations, tab]);

  //functions
  const searchPresentation = (e) => {
    const searchedTitle = e.target?.value?.toLowerCase();
    setTab("all");
    searchParam.current = searchedTitle;
    const filteredPresentations = presentations?.data?.filter(
      (presentation) => {
        const title = presentation?.title?.toLowerCase();
        return title?.includes(searchedTitle);
      }
    );

    setAllPresentations(filteredPresentations);
  };

  return (
    <section>
      <section className="relative">
        <AdminNavbar navProps={navProps} />
      </section>

      <section className="mt-20">
        <NavigationTab
          tab={tab}
          setTab={setTab}
          searchPresentation={searchPresentation}
        />
        {/* loader */}
        {presentations?.isLoading && <Loader />}
        {/*  */}
        {!presentations?.isLoading && (
          <>
            <section className="flex flex-col justify-center w-full pt-10 pb-20">
              <div className="flex items-center justify-center w-full">
                {((grouppedPresentations?.length < 1 && tab !== "all") ||
                  allPresentations?.length < 1) && (
                  <div className="h-[60vh] flex  justify-center items-center w-full ">
                    {searchParam.current ? (
                      <p className="text-2xl font-medium text-center">
                        {/* No Presentation found */}
                        <img src={notFound} alt="empty " className="" />
                      </p>
                    ) : (
                      <img src={empty} alt="empty " className="" />
                    )}
                  </div>
                )}
              </div>
              <section className="grid w-full grid-cols-1 lg:gap-x-8 lg:gap-y-8 gap-y-10 lg:grid-cols-3 md:grid-cols-2">
                {/* all presentations  */}
                {tab === "all" &&
                  allPresentations?.map((presentation, index) => {
                    return (
                      <PresentationCard
                        presentation={presentation}
                        key={index}
                      />
                    );
                  })}

                {/* groupped presentations  */}
                {grouppedPresentations?.length > 0 &&
                  tab !== "all" &&
                  grouppedPresentations?.map((presentation, index) => {
                    return (
                      <PresentationCard
                        presentation={presentation}
                        key={index}
                      />
                    );
                  })}
              </section>
            </section>
          </>
        )}
      </section>
    </section>
  );
}

import { ButtonInterface } from "interfaces/interfaces-ui";
import React from "react";

export const CButton = ({
  loading,
  text,
  icon,
  iconPosition = "right",
  variant = "plain",
}: ButtonInterface) => {
  return (
    <button
      className={`${
        variant == "plain"
          ? "border border-[#AFAFAF] bg-transparent text-[#545454]"
          : ""
      }  px-4 py-2 rounded-lg font-semibold text-sm flex items-center gap-2 `}
    >
      {icon && iconPosition == "left" && (
        <span>
          <img src={icon} />
        </span>
      )}
      {text}
      {icon && iconPosition == "right" && (
        <span>
          <img src={icon} />
        </span>
      )}
    </button>
  );
};

import { useEffect, useState } from 'react';

//components
import BriefcaseList from './BriefcaseList';

//assets
import AdminNav from './AdminNav';

// libraries
import { useSelector } from 'react-redux';

// Slices
import { RootState } from 'store/store';

//Types
import { BriefcaseItemsType } from 'types/Admin/Briefcase/BriefcaseTypes';

export default function AllBriefCase() {
  //useselector
  const briefcases = useSelector(
    (state: RootState) => state.briefcase.briefcases
  );

  //states
  const [briefcaseItems, setBriefcaseItems] = useState<
    BriefcaseItemsType[] | []
  >([]);

  useEffect(() => {
    setBriefcaseItems(briefcases.data);
  }, [briefcases.data]);

  console.log(briefcaseItems);

  return (
    <section className='relative'>
      {/* Nav */}
      <AdminNav />
      <section className='mt-20'>
        <BriefcaseList briefcaseItems={briefcaseItems} />
      </section>
    </section>
  );
}

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  _createBriefcase,
  _getBriefcases,
  _updateBriefcase,
  _getBriefcaseDocuments,
  _addBriefcaseFile,
  _setBriefcaseDocumentPermission,
  _removeBriefcase,
  _removeBriefcaseFile,
} from 'services/Briefcase/briefcaseServices';
import { BriefcaseItemsType } from 'types/Admin/Briefcase/BriefcaseTypes';

interface InitialState {
  briefcases: {
    isLoading: boolean;
    data: BriefcaseItemsType[];
  };
  briefcaseDetails: {
    isLoading: boolean;
    data: BriefcaseItemsType | null;
  };
  briefcaseDocuments: {
    isLoading: boolean;
    data: any;
  };
}

interface AddBriefcaseFilePayload {
  formData: FormData;
  spaceId: string;
}
interface SetBriefcaseDocumentPermissionPayload {
  data: {
    isPrivate: boolean;
    documentId: string;
  };
  spaceId: string;
}

const initialState: InitialState = {
  briefcases: {
    isLoading: false,
    data: [],
  },
  briefcaseDetails: {
    isLoading: true,
    data: null,
  },
  briefcaseDocuments: {
    isLoading: false,
    data: [],
  },
};

//get
export const getBriefcases = createAsyncThunk(
  'briefcase/getBriefcases',
  async (teamId: string) => {
    const response = await _getBriefcases(teamId);
    console.log(response.data);
    return response.data;
  }
);

export const getBriefcaseDocuments = createAsyncThunk(
  'briefcase/getBriefcaseDocuments',
  async (spaceId: string) => {
    const response = await _getBriefcaseDocuments(spaceId);
    return response.data;
  }
);

//POST
export const createBriefcase = createAsyncThunk(
  'briefcase/createBriefcase',
  async (data: FormData) => {
    const response = await _createBriefcase(data);
    return response.data;
  }
);

export const updateBriefcase = createAsyncThunk(
  'briefcase/updateBriefcase',
  async (data: FormData) => {
    const response = await _updateBriefcase(data);
    return response.data;
  }
);
export const addBriefcaseFile = createAsyncThunk(
  'briefcase/addBriefcaseFile',
  async ({ spaceId, formData }: AddBriefcaseFilePayload) => {
    // spaceId, formData
    // async ({ data, spaceId }: { data: FormData; spaceId: string }) => {
    console.log('slice ran', formData);
    const response = await _addBriefcaseFile(spaceId, formData);
    return response.data;
  }
);
export const setBriefcaseDocumentPermission = createAsyncThunk(
  'briefcase/setBriefcasePermission',
  async ({ spaceId, data }: SetBriefcaseDocumentPermissionPayload) => {
    const response = await _setBriefcaseDocumentPermission(spaceId, data);
    return response.data;
  }
);
export const removeBriefcase = createAsyncThunk(
  'briefcase/removeBriefcase',
  async (spaceId: string) => {
    const response = await _removeBriefcase(spaceId);
    return response.data;
  }
);
export const removeBriefcaseFile = createAsyncThunk(
  'briefcase/removeBriefcaseFile',
  async ({ spaceId, documentId }: { spaceId: string; documentId: string }) => {
    const response = await _removeBriefcaseFile(spaceId, documentId);
    return response.data;
  }
);

export const briefcaseSlice = createSlice({
  name: 'briefcase',
  initialState,
  reducers: {
    setBriefcaseDetailsAction(state, action: PayloadAction<number>) {
      if (state.briefcases.data) {
        state.briefcaseDetails.data = state.briefcases.data[action.payload];
      }
    },
  },

  // (state, action: PayloadAction<number>) => {
  //   state.value += action.payload;
  // }
  extraReducers: (builder) => {
    builder
      .addCase(getBriefcases.pending, (state) => {
        state.briefcases.isLoading = true;
        state.briefcases.data = [];
      })
      .addCase(
        getBriefcases.fulfilled,
        (state, action: PayloadAction<BriefcaseItemsType[]>) => {
          state.briefcases.isLoading = false;
          state.briefcases.data = action.payload;
        }
      )
      .addCase(getBriefcases.rejected, (state) => {
        state.briefcases.isLoading = false;
      });

    builder
      .addCase(getBriefcaseDocuments.pending, (state) => {
        state.briefcaseDocuments.isLoading = true;
        state.briefcaseDocuments.data = [];
      })
      .addCase(
        getBriefcaseDocuments.fulfilled,
        (state, action: PayloadAction<BriefcaseItemsType[]>) => {
          state.briefcaseDocuments.isLoading = false;
          state.briefcaseDocuments.data = action.payload;
        }
      )
      .addCase(getBriefcaseDocuments.rejected, (state) => {
        state.briefcaseDocuments.isLoading = false;
      });

    // builder
    //   .addCase(setBriefcaseDocumentPermission.pending, (state) => {
    //     state.briefcaseDocuments.isLoading = true;
    //     state.briefcaseDocuments.data = [];
    //   })
    //   .addCase(
    //     setBriefcaseDocumentPermission.fulfilled,
    //     (state, action: PayloadAction<BriefcaseItemsType[]>) => {
    //       state.briefcaseDocuments.isLoading = false;
    //       state.briefcaseDocuments.data = action.payload;
    //     }
    //   )
    //   .addCase(setBriefcaseDocumentPermission.rejected, (state) => {
    //     state.briefcaseDocuments.isLoading = false;
    //   });
  },
});

// export const getBriefcaseState = (state) => state.briefcase;
export const {
  setBriefcaseDetailsAction,
  // clearteamMembers,
  // setSideImages,
  // clearSideImages,
  // setActiveSlide,
} = briefcaseSlice.actions;
export default briefcaseSlice.reducer;
